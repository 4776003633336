import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useAppSelector } from '../../storeHooks';
const UserAvatar = () => {
    const avatarUrl = useAppSelector((state) => state.user.avatarUrl);
    return (_jsx("img", { src: avatarUrl, alt: "avatar", className: "user-avatar", style: {
            width: 42,
            height: 42,
            borderRadius: '50%',
        } }));
};
export default React.memo(UserAvatar);
