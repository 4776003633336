import React from 'react';
import $ from 'jquery';
import _t from 'counterpart';
import marginVideoWebm from '../../videos/margin-video.webm';
import marginVideo from '../../videos/margin-video.mp4';
import { actions, selectors } from 'wintrado-api';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from 'components/Button/Button';

class ModalLowMargin extends React.PureComponent {
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.marginCall !== this.props.marginCall && this.props.marginCall && !this.props.lowMarginModalShown) {
			this.showModal();
		}
	}

	showModal = () => {
		$('#low_margin_modal').modal('show');
		this.props.setLowMarginModalShown();
	};

	handleCloseModal = () => {
		$('#low_margin_modal').modal('hide');
	};

	render() {
		return (
			<div
				id="low_margin_modal"
				className="modal modal--low_margin fade bd-example-modal-sm"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="mySmallModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						<button type="button" className="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>

						<p className="title">{_t.translate('modal.margin_low.title')}</p>

						<div className="notice">
							<p>{_t.translate('modal.margin_low.description')}</p>
						</div>

						<div className="videoholder">
							<video muted loop autoPlay playsInline>
								<source src={marginVideoWebm} type="video/webm" />
								<source src={marginVideo} type="video/mp4" />
							</video>
						</div>

						<div className="modal_btns">
							<div className="holder">
								<Button green onClick={this.handleCloseModal}>
									{_t.translate('main.i_got_it')}
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		marginCall: selectors.marginCallSelector(state),
		lowMarginModalShown: state.events.lowMarginModalShown,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setLowMarginModalShown: actions.setLowMarginModalShown,
		},
		dispatch
	);
};
export default connect(mapStateToProps, mapDispatchToProps)(ModalLowMargin);
