import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { formatCurrency } from 'utils/currency';
import currentFreeMarginIcon from '../icons/current_free_margin.svg';
import requiredAmountIcon from '../icons/required_amount.svg';
import missingAmountIcon from '../icons/missing_amount.svg';
import * as Sentry from '@sentry/react';
import _t from 'counterpart';
import { isSet } from '../helpers';
const InsufficientBalanceAmounts = ({ currentFreeMargin, requiredAmount, currency }) => {
    if (!isSet(requiredAmount) && !isSet(currentFreeMargin)) {
        Sentry.captureException(Error('requiredAmount & currentFreeMargin are required for InsufficientBalanceAmounts'));
        return null;
    }
    return (_jsxs("div", Object.assign({ className: "insufficient-balance-amounts" }, { children: [_jsxs("div", Object.assign({ className: "insufficient-balance-amounts__item" }, { children: [_jsx("img", { src: currentFreeMarginIcon, className: "insufficient-balance-amounts__item__icon", alt: "" }), _jsx("span", Object.assign({ className: "insufficient-balance-amounts__item__label" }, { children: _t.translate('main.current_free_margin') })), _jsx("span", Object.assign({ className: "insufficient-balance-amounts__item__value" }, { children: formatCurrency(currentFreeMargin, currency) }))] })), _jsxs("div", Object.assign({ className: "insufficient-balance-amounts__item" }, { children: [_jsx("img", { src: requiredAmountIcon, className: "insufficient-balance-amounts__item__icon", alt: "" }), _jsx("span", Object.assign({ className: "insufficient-balance-amounts__item__label" }, { children: _t.translate('main.required_amount') })), _jsx("span", Object.assign({ className: "insufficient-balance-amounts__item__value" }, { children: formatCurrency(requiredAmount, currency) }))] })), _jsx("hr", { className: "insufficient-balance-amounts__divider" }), _jsxs("div", Object.assign({ className: "insufficient-balance-amounts__item" }, { children: [_jsx("img", { src: missingAmountIcon, className: "insufficient-balance-amounts__item__icon", alt: "" }), _jsx("span", Object.assign({ className: "insufficient-balance-amounts__item__label" }, { children: _t.translate('main.missing_amount') })), _jsx("span", Object.assign({ className: "insufficient-balance-amounts__item__value insufficient-balance-amounts__item__value--accent" }, { children: formatCurrency(requiredAmount - currentFreeMargin, currency) }))] }))] })));
};
export default InsufficientBalanceAmounts;
