import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import classNames from 'classnames';
import AnyLink from './global/AnyLink';
import { useAppSelector } from '../storeHooks';
const CustomBanner = ({ asStat }) => {
    const activeBanner = useAppSelector((state) => state.broker.activeBanner);
    const renderBannerImage = useCallback(() => {
        return _jsx("img", { src: activeBanner.fileLink, alt: "banner", className: "custom-banner__image" });
    }, [activeBanner]);
    if (!activeBanner) {
        return null;
    }
    if (!activeBanner.link) {
        renderBannerImage();
    }
    return (_jsxs("div", Object.assign({ className: classNames('custom-banner', { 'custom-banner--as-stat': asStat }) }, { children: [!activeBanner.link && renderBannerImage(), activeBanner.link && (_jsx(AnyLink, Object.assign({ className: "custom-banner__link", to: activeBanner.link, target: activeBanner.linkTarget }, { children: renderBannerImage() })))] })));
};
CustomBanner.defaultProps = {
    asStat: false,
};
export default CustomBanner;
