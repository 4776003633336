import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { BackButton } from 'components/BackButton';
import { useAppSelector } from '../../storeHooks';

export const MobilePageHeader = ({ isEmbeddedCashier = false }) => {
	const { data: affiliateLogo, isLoading: affiliateLogoIsLoading } = useAppSelector((state) => state.greyLabelLogo);

	const logoUrl = useMemo(
		() => affiliateLogo?.rectangularLogo ?? require(`../../images/brands/${__BRAND__}/logo-mobile.png`),
		[affiliateLogo]
	);

	return (
		<div className="mobile-page-header">
			<BackButton useCloseIcon={isEmbeddedCashier} />
			{!affiliateLogoIsLoading && (
				<NavLink exact to="/" className="mobile-page-header__logo">
					<img src={logoUrl} className="img-fluid logo-brand" alt="logo" />
				</NavLink>
			)}
		</div>
	);
};
