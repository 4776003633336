import React from 'react';
import _t from 'counterpart';

export default class ModalApiErrorLogin extends React.Component {
	render() {
		return (
			<div
				id="api_error_login"
				className="modal bd-example-modal-sm"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="mySmallModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						{_t.translate('modal.api_error_login')}
						<div className="modal_btns">
							<a
								href="#"
								data-dismiss="modal"
								aria-label="Close"
								onClick={() => window.location.reload()}
								className="btn right"
							>
								{_t.translate('modal.okay')}
							</a>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
