import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import config from 'config';
import { PageLayout } from 'components/global/PageLayout';
import _t from 'counterpart';
import { CashierIframeWrapper } from 'components/CashierIframeWrapper';
import { useAppSelector } from '../../storeHooks';
const PageMetatrader = () => {
    const token = useAppSelector((state) => state.user.token);
    const { metatraderURL } = config.cashier;
    if (!metatraderURL || !token) {
        return null;
    }
    const url = metatraderURL(token, _t.getLocale());
    return (_jsx(PageLayout, Object.assign({ className: "metatrader", isEmbeddedCashier: true }, { children: _jsx(CashierIframeWrapper, { url: url }) })));
};
export default React.memo(PageMetatrader);
