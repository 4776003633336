import React, { useEffect } from 'react';
import Loader from 'components/Loader/Loader';
import { useDispatch } from 'react-redux';
import { loginLoaderHidden, loginLoaderShown } from 'wintrado-api/src/actions';

const LoginLoader = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(loginLoaderShown());

		return () => {
			dispatch(loginLoaderHidden());
		};
	}, []);

	return <Loader />;
};

export default LoginLoader;
