var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo } from "react";
import { PageLayout } from "components/global/PageLayout";
import _t from "counterpart";
import Input from "components/form/Input";
import { useForm } from "react-hook-form";
import { useAppSelector } from "../../storeHooks";
import { formatCurrency } from "utils/currency";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { crmService } from "wintrado-api";
import { isSet, requestOAuthLogin } from "../../helpers";
import Notice from "components/global/Notice";
import Loader from "components/Loader/Loader";
import Button from "components/Button/Button";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
const schema = z
    .object({
    amount: z.coerce.number().nonnegative(),
})
    .required();
const PageCopyTradingStrategy = () => {
    var _a, _b;
    const { register, handleSubmit, formState: { errors }, } = useForm({
        resolver: zodResolver(schema),
    });
    const { accountCurrency, balance, communicationLanguage } = useAppSelector((state) => state.user);
    const { id } = useParams();
    const { data, error, isLoading, isFetching } = useQuery(['trading-strategy', id], () => crmService.getCopyTradingStrategy(id));
    const copyStrategyMutation = useMutation((amount) => crmService.followStrategy(id, amount));
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        copyStrategyMutation.mutate(data.amount);
    });
    const onSwitchToWallet = useCallback(() => {
        requestOAuthLogin(communicationLanguage, copyStrategyMutation.data.result.username);
    }, [communicationLanguage, copyStrategyMutation.data]);
    const renderContent = useMemo(() => {
        var _a;
        if (copyStrategyMutation.isSuccess) {
            return (_jsx(Notice, { type: "success", title: _t('copy_trading.copy_successful_title'), subtitle: _t('copy_trading.copy_successful_subtitle', { strategyName: data.name }), buttonLabel: _t('copy_trading.switch_to_copytrading_wallet'), buttonClickHandler: onSwitchToWallet }));
        }
        if (isSet(data)) {
            return (_jsxs("form", Object.assign({ className: "d-flex flex-column", onSubmit: handleSubmit(onSubmit) }, { children: [_jsx(Input, Object.assign({}, register('amount'), { label: _t('copy_trading.amount'), placeholder: _t('copy_trading.enter_amount'), error: (_a = errors.amount) === null || _a === void 0 ? void 0 : _a.message })), _jsxs("div", Object.assign({ className: "stat mt-3" }, { children: [_jsx("span", Object.assign({ className: "stat__label" }, { children: _t('copy_trading.available_funds') })), _jsx("span", Object.assign({ className: "stat__value" }, { children: formatCurrency(balance, accountCurrency) }))] })), _jsx("h6", Object.assign({ className: "mt-5" }, { children: _t('copy_trading.fees_and_conditions').toLocaleUpperCase() })), _jsxs("div", Object.assign({ className: "stat" }, { children: [_jsx("span", Object.assign({ className: "stat__label" }, { children: _t('copy_trading.min_investment') })), _jsx("span", Object.assign({ className: "stat__value" }, { children: data.minimumInvest }))] })), _jsxs("div", Object.assign({ className: "stat" }, { children: [_jsx("span", Object.assign({ className: "stat__label" }, { children: _t('copy_trading.performance_fee') })), _jsx("span", Object.assign({ className: "stat__value" }, { children: data.feeStructure.performanceFeePercent }))] })), _jsx("span", Object.assign({ className: "disclaimer" }, { children: _t('copy_trading.copy_disclaimer') })), _jsx(Button, Object.assign({ green: true, className: "mt-3", disabled: copyStrategyMutation.isLoading }, { children: _t('copy_trading.start_copying') })), copyStrategyMutation.isLoading && _jsx(Loader, { semiTransparentBackground: true })] })));
        }
        return null;
    }, [
        copyStrategyMutation.isSuccess,
        copyStrategyMutation.isLoading,
        data,
        handleSubmit,
        onSubmit,
        register,
        (_a = errors.amount) === null || _a === void 0 ? void 0 : _a.message,
        balance,
        accountCurrency,
    ]);
    return (_jsx(PageLayout, Object.assign({ title: (_b = data === null || data === void 0 ? void 0 : data.name) !== null && _b !== void 0 ? _b : '', loading: isLoading || isFetching, error: error === null || error === void 0 ? void 0 : error.message, showDesktopBackButton: true }, { children: _jsx("div", Object.assign({ className: "copy-trading-strategy" }, { children: renderContent })) })));
};
export default React.memo(PageCopyTradingStrategy);
