import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo, useState } from 'react';
import Input from 'components/form/Input';
import Button from 'components/Button/Button';
import filtersIcon from '../../../icons/filters.svg';
import styles from './StrategyFilters.module.scss';
import { playClickSound } from '../../../helpers';
import _t from 'counterpart';
import Spacer from 'components/Spacer/Spacer';
import searchIcon from '../../../icons/search.svg';
import sortIcon from '../../../icons/sort.svg';
import RangeFiltersDropdown from 'components/copyTrading/RangeFiltersDropdown/RangeFiltersDropdown';
import debounce from 'lodash.debounce';
import { StrategySortBy } from 'wintrado-api';
import Badge from 'components/Badge/Badge';
import { Desktop, Mobile, useIsDesktop } from 'utils/responsiveHelpers';
const clearedFilters = {
    minInvestmentAmount: null,
    roiSinceStart: null,
    followers: null,
};
const StrategyFilters = ({ searchValueChanged, sortValueChanged, filtersChanged, initialValues }) => {
    const [searchValue, setSearchValue] = useState(initialValues.searchValue);
    const [sortValue, setSortValue] = useState(initialValues.sortValue);
    const [rangeFilters, setRangeFilters] = useState(initialValues.rangeFilters);
    const [filtersDropdownVisible, setFiltersDropdownVisible] = useState(false);
    const isDesktop = useIsDesktop();
    const filtersScope = {
        minInvestmentAmount: { min: 0, max: 10000 },
        roiSinceStart: { min: -1000, max: 1000 },
        followers: { min: 0, max: 10000 },
    };
    const rangeFiltersActive = useMemo(() => rangeFilters.roiSinceStart || rangeFilters.followers || rangeFilters.minInvestmentAmount, [rangeFilters]);
    const noOfActiveFilters = useMemo(() => {
        return Object.values(rangeFilters).reduce((activeFilters, filter) => {
            if (filter) {
                return activeFilters + 1;
            }
            return activeFilters;
        }, 0);
    }, [rangeFilters]);
    const handleCloseFiltersDropdown = useCallback(() => {
        setFiltersDropdownVisible(false);
    }, [setFiltersDropdownVisible]);
    const debouncedSearchValueHandler = useMemo(() => debounce(searchValueChanged, 300), [searchValueChanged]);
    const handleSearchInputChange = useCallback((event) => {
        setSearchValue(event.target.value);
        debouncedSearchValueHandler(event.target.value);
    }, [debouncedSearchValueHandler, setSearchValue]);
    const handleSortByChange = (e) => {
        e.preventDefault();
        const sortBy = e.currentTarget.getAttribute('data-sort-by');
        setSortValue(sortBy);
        sortValueChanged(sortBy);
        playClickSound();
    };
    const handleMoreFiltersClicked = useCallback(() => {
        playClickSound();
        setFiltersDropdownVisible(true);
    }, [setFiltersDropdownVisible]);
    const handleClearFilters = () => {
        setRangeFilters(clearedFilters);
        filtersChanged(clearedFilters);
    };
    const handleApplyRangeFilters = (filters) => {
        setRangeFilters(filters);
        filtersChanged(filters);
        handleCloseFiltersDropdown();
    };
    const renderRangeFilters = useMemo(() => (_jsxs("div", Object.assign({ className: styles.rangeFiltersContainer }, { children: [_jsxs(Button, Object.assign({ onClick: handleMoreFiltersClicked, mobileFullWidth: true, gray: true }, { children: [_jsx("img", { src: filtersIcon, alt: "filters", className: styles.filterIcon }), _jsx("span", Object.assign({ className: styles.moreFiltersLabel }, { children: _t('copy_trading.filters.more_filters') })), noOfActiveFilters > 0 && (_jsxs(_Fragment, { children: [_jsx(Spacer, { x: true }), _jsx(Badge, { children: noOfActiveFilters })] }))] })), filtersDropdownVisible && (_jsx(RangeFiltersDropdown, { onClose: handleCloseFiltersDropdown, onApply: handleApplyRangeFilters, currentFilters: rangeFilters, filtersScope: filtersScope })), rangeFiltersActive && (_jsxs(_Fragment, { children: [_jsx(Spacer, { x: true }), _jsx(Button, Object.assign({ transparentGray: true, onClick: handleClearFilters }, { children: _t('copy_trading.filters.clear_filters') }))] }))] }))), [
        filtersDropdownVisible,
        filtersScope,
        handleApplyRangeFilters,
        handleClearFilters,
        handleCloseFiltersDropdown,
        handleMoreFiltersClicked,
        noOfActiveFilters,
        rangeFilters,
        rangeFiltersActive,
    ]);
    const renderSorter = useMemo(() => (_jsxs("div", Object.assign({ className: styles.sortContainer }, { children: [_jsx("span", Object.assign({ className: styles.sortLabel }, { children: "Sort by" })), _jsxs("div", Object.assign({ className: styles.dropdownContainer }, { children: [_jsxs(Button, Object.assign({ onClick: playClickSound, gray: true, "data-toggle": "dropdown", "aria-haspopup": "true", "aria-expanded": "false", textStart: true, withDropdownToggle: isDesktop }, { children: [_jsx("img", { src: sortIcon, alt: "sort", className: styles.sortIcon }), _jsx("span", Object.assign({ className: styles.sortButtonLabel }, { children: _t(`copy_trading.sort_by.${sortValue}`) }))] })), _jsx("div", Object.assign({ className: `dropdown-menu ${styles.dropdownMenu}` }, { children: Object.values(StrategySortBy).map((item) => {
                            return (_jsx("div", { children: _jsx("a", Object.assign({ type: "button", href: "#", className: `${styles.dropdownItem} dropdown-item`, "data-sort-by": item, onClick: handleSortByChange }, { children: _jsx("span", { children: _t(`copy_trading.sort_by.${item}`) }) })) }, item));
                        }) }))] }))] }))), [handleSortByChange, isDesktop, sortValue]);
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsxs("div", Object.assign({ className: styles.searchFilterContainer }, { children: [_jsx(Input, { placeholder: _t('copy_trading.enter_strategy_name'), value: searchValue, onChange: handleSearchInputChange, condensed: true, suffixIcon: searchIcon }), _jsxs(Desktop, { children: [_jsx(Spacer, { x: true, large: true }), renderRangeFilters] }), _jsxs(Mobile, { children: [_jsx(Spacer, { x: true }), renderSorter] })] })), _jsx(Desktop, { children: renderSorter }), _jsx(Mobile, { children: renderRangeFilters })] })));
};
export default React.memo(StrategyFilters);
