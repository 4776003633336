import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { playClickSound } from '../../helpers';
import _t from 'counterpart';
import Button from 'components/Button/Button';
import logoutIcon from '../../images/modals/logout.svg';
const ModalLogout = ({ onClickLogout }) => {
    return (_jsx("div", Object.assign({ id: "logout_modal", className: "modal fade bd-example-modal-sm", tabIndex: -1, role: "dialog", "aria-labelledby": "logout-modal", "aria-hidden": "true" }, { children: _jsx("div", Object.assign({ className: "modal-dialog" }, { children: _jsxs("div", Object.assign({ className: "modal-content" }, { children: [_jsx("div", Object.assign({ className: "illustration" }, { children: _jsx("img", { src: logoutIcon, alt: "logout" }) })), _jsx("p", Object.assign({ className: "title" }, { children: _t.translate('modal.logout_desc') })), _jsx("div", Object.assign({ className: "modal_btns" }, { children: _jsxs("div", Object.assign({ className: "holder" }, { children: [_jsx(Button, Object.assign({ green: true, onClick: playClickSound, "data-dismiss": "modal", "aria-label": "Close" }, { children: _jsx("span", { children: _t.translate('modal.close') }) })), _jsx(Button, Object.assign({ red: true, onClick: onClickLogout }, { children: _jsx("span", { children: _t.translate('modal.logout') }) }))] })) }))] })) })) })));
};
export default React.memo(ModalLogout);
