import React from 'react';
import { playClickSound } from '../../helpers';
import { Desktop, Mobile } from 'utils/responsiveHelpers';
import $ from 'jquery';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _t from 'counterpart';
import AnyLink from 'components/global/AnyLink';
import Button from 'components/Button/Button';
import competition1Prize from '../../images/modals/competition-1-prize.svg';
import competition2Prizes from '../../images/modals/competition-2-prizes.svg';
import competition3Prizes from '../../images/modals/competition-3-prizes.svg';
import classNames from 'classnames';

class ModalCompetition extends React.PureComponent {
	modalShown = false;

	leaderboardAlreadyVisited = false;

	timeout;

	componentDidUpdate(prevProps, prevState) {
		const { currentCompetition, location } = this.props;

		if (location.pathname.indexOf('leaderboard') >= 0) {
			this.leaderboardAlreadyVisited = true;
		}

		if (
			!currentCompetition?.data?.competition ||
			currentCompetition?.data?.participation ||
			!currentCompetition.data.competition.promoted ||
			this.modalShown ||
			this.leaderboardAlreadyVisited
		) {
			return;
		}

		const startTime = new Date(currentCompetition.data.competition.start);

		if (startTime.getTime() < new Date().getTime()) {
			this.modalShown = true;

			this.timeout = setTimeout(() => {
				$('#competition_modal').modal('show');
			}, 5500);
		}
	}

	componentWillUnmount() {
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
	}

	handleMobileJoinClicked = () => {
		$('#competition_modal').modal('hide');
		playClickSound();
	};

	handleDesktopJoinClicked = () => {
		this.handleMobileJoinClicked();
		this.props.setRightSideWidget('leaderboard');
	};

	getImageToUse = () => {
		const { currentCompetition } = this.props;
		if (!currentCompetition?.data?.competition) {
			return null;
		}
		const { firstPrizeInUSD, secondPrizeInUSD, thirdPrizeInUSD } = currentCompetition.data.competition;
		if (!firstPrizeInUSD) {
			throw new Error('firstPrizeInUSD must be defined for an active competition');
		}
		if (firstPrizeInUSD && secondPrizeInUSD && thirdPrizeInUSD) {
			return competition3Prizes;
		}
		if (firstPrizeInUSD && secondPrizeInUSD) {
			return competition2Prizes;
		}
		return competition1Prize;
	};

	render() {
		const { currentCompetition } = this.props;
		const firstPrize = currentCompetition?.data?.competition?.firstPrizeInUSD;
		const secondPrize = currentCompetition?.data?.competition?.secondPrizeInUSD;
		const thirdPrize = currentCompetition?.data?.competition?.thirdPrizeInUSD;

		return (
			<div
				id="competition_modal"
				className="modal modal--competition fade bd-example-modal-sm"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="mySmallModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						<button type="button" className="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>

						<p className="title">{_t.translate('leaderboard.competition_title')}</p>

						<div className="illustration">
							<img src={this.getImageToUse()} alt="prizes" />
							<div className="small-labels">
								<span className={classNames({ hidden: secondPrize === null })}>{`$${secondPrize}`}</span>
								<span className={classNames({ hidden: thirdPrize === null })}>{`$${thirdPrize}`}</span>
							</div>
							<span className="big-label">{`$${firstPrize}`}</span>
						</div>

						<div className="notice">
							<p>{_t.translate('leaderboard.competition_description', { amount: `$${firstPrize}` })}</p>
						</div>

						<div className="modal_btns">
							<div className="holder">
								<Desktop>
									<Button green onClick={this.handleDesktopJoinClicked}>
										{_t.translate('leaderboard.join_now')}
									</Button>
								</Desktop>
								<Mobile>
									<AnyLink to="/leaderboard" onClick={this.handleMobileJoinClicked}>
										<Button green>{_t.translate('leaderboard.join_now')}</Button>
									</AnyLink>
								</Mobile>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		currentCompetition: state.leaderboard.currentCompetition,
	};
};

export default compose(withRouter, connect(mapStateToProps))(ModalCompetition);
