import React from 'react';
import _t from 'counterpart';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Button from 'components/Button/Button';
import tipIcon from '../../icons/tip.svg';

class PopupNotifications extends React.PureComponent {
	state = {
		notifications: {},
	};

	showNotification = (notification) => {
		this.setState((prevState) => {
			return {
				notifications: {
					...prevState.notifications,
					[notification.id]: notification,
				},
			};
		});
	};

	hideNotification = (notificationId) => {
		this.setState((prevState) => {
			const filteredNotifications = { ...prevState.notifications };
			delete filteredNotifications[notificationId];
			return {
				notifications: filteredNotifications,
			};
		});
	};

	renderSingleNotification = (notificationId) => {
		const { notifications } = this.state;
		const notification = notifications[notificationId];
		const {
			primaryButtonTitleKey,
			secondaryButtonTitleKey,
			onPrimaryButtonClicked,
			onSecondaryButtonClicked,
			labelKey = 'main.tip',
			descriptionKey,
			descriptionExtra,
		} = notification;
		return (
			<CSSTransition key={notificationId} className="popup-notification" timeout={500}>
				<div>
					<div className="popup-notification__container">
						<div className="holder">
							<img className="icon" src={tipIcon} alt="hint" />
						</div>
						<div className="content">
							<div className="tip">
								<div className="label-container">
									<span>{`${_t.translate(labelKey)}: `}</span>
								</div>
								<div className="description">
									<span>{_t.translate(descriptionKey, descriptionExtra)}</span>
								</div>
							</div>
							<div className="buttons-container">
								<Button transparentGray onClick={onSecondaryButtonClicked}>
									{_t.translate(secondaryButtonTitleKey)}
								</Button>
								<Button green onClick={onPrimaryButtonClicked}>
									{_t.translate(primaryButtonTitleKey)}
								</Button>
							</div>
						</div>
					</div>
				</div>
			</CSSTransition>
		);
	};

	render() {
		const { notifications } = this.state;
		return (
			<div id="popup-notifications">
				<TransitionGroup>{Object.keys(notifications).map(this.renderSingleNotification)}</TransitionGroup>
			</div>
		);
	}
}

export default PopupNotifications;
