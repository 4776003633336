import React from 'react';
import { actions, PositionPlatform, PositionSide } from 'wintrado-api';
import { formatCurrency } from 'utils/currency';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DateRangeHeader from 'components/DateRangeHeader';
import TotalFooter from 'components/TotalFooter';
import moment from 'moment';
import 'moment/min/locales';
import ClosedPosition from 'components/ClosedPosition';
import { PageLayout } from 'components/global/PageLayout';
import { getIconForCloseReasonIfExists } from '../../helpers';
import Pill from 'components/Pill';
import TrendingUp from 'components/icons/TrendingUp';
import TrendingDown from 'components/icons/TrendingDown';
import { Desktop, Mobile } from 'utils/responsiveHelpers';
import InstrumentIcon from "components/InstrumentIcon/InstrumentIcon";

const _t = require('counterpart');

class PageAccountStatement extends React.Component {
	componentDidMount() {
		const { getAccountHistory } = this.props;
		getAccountHistory();
	}

	renderMobilePositionLine = (position, index) => {
		const { accountCurrency, instruments } = this.props;
		return (
			<ClosedPosition
				key={`position${index}`}
				position={position}
				accountCurrency={accountCurrency}
				instruments={instruments}
			/>
		);
	};

	renderDesktopPositionLine = (position, index) => {
		const { instruments, accountCurrency } = this.props;
		const posSideTerm = position.side.toLowerCase();
		const currentPositionInstrument = instruments[position.symbol];
		const priceDecimals = currentPositionInstrument ? currentPositionInstrument.decimals : 5;
		const iconForCloseReason = getIconForCloseReasonIfExists(position.closeReason);

    return (
      <tr key={`pos-${index}`}>
        <td>
          <strong>{position.positionId}</strong>
        </td>
        <td className="description">
          <div className="mr-2">
            <InstrumentIcon symbol={currentPositionInstrument.symbol} imgUrl={currentPositionInstrument.iconUrl} />
          </div>
          <span className={`trade-instrument-name ${posSideTerm}`}>
            {currentPositionInstrument ? currentPositionInstrument.symbol : position.symbol}
          </span>
        </td>
        <td className="side">
          <strong className="title">{_t.translate(`main.${posSideTerm}`)}</strong>
          {position.side === PositionSide.BUY ? <TrendingUp /> : <TrendingDown />}
        </td>
        <td>
          <span className="trade-time">
            <strong>{moment(position.openedAt).format("HH:mm:ss")}</strong> <br />{" "}
            {moment(position.openedAt).format("ll")}
          </span>
        </td>
        <td>
          <span className="trade-time">
            <strong>{moment(position.closedAt).format("HH:mm:ss")}</strong> <br />{" "}
            {moment(position.closedAt).format("ll")}
          </span>
        </td>
        <td>
          <strong>{Number(position.openPrice).toFixed(priceDecimals)}</strong>
        </td>
        <td>
          <strong>{Number(position.closePrice).toFixed(priceDecimals)}</strong>
        </td>
        <td className="stop-reason">
          <div className="holder">
            {!!iconForCloseReason && <img src={iconForCloseReason} alt={position.closeReason} />}
            <strong>{_t.translate(`stop_reasons.${position.closeReason}`)}</strong>
          </div>
        </td>

				<td className={`trade-outcome ${position.profitLossInAccountCurrency > 0 ? 'win' : 'loss'}`}>
					{formatCurrency(position.profitLossInAccountCurrency, accountCurrency)}
				</td>

				<td className="platform">
					<Pill blue={position.platform === PositionPlatform.MT4}>
						{position.platform === PositionPlatform.MT4 ? 'MT4' : 'APP'}
					</Pill>
				</td>
			</tr>
		);
	};

	renderMobileAccountStatement = () => {
		const { trades, startDate, endDate, profitLossInAccountCurrency, accountCurrency } = this.props;
		return (
			<>
				<DateRangeHeader startDate={startDate} endDate={endDate} />
				<div className="col-md-12 wt-scroll table-wrapper">{trades.map(this.renderMobilePositionLine)}</div>
				<TotalFooter profitLossInAccountCurrency={profitLossInAccountCurrency} accountCurrency={accountCurrency} />
			</>
		);
	};

	renderDesktopAccountStatement = () => {
		const { trades, profitLossInAccountCurrency, accountCurrency } = this.props;
		return (
			<>
				<div className="col-md-12 wt-scroll wt-scroll--account-statement">
					<table className="wt-table account-statement-wrapper">
						<thead>
							<tr>
								<th>
									<span>{_t.translate('page.position_id')}</span>
								</th>
								<th>
									<span>{_t.translate('page.desc')}</span>
								</th>
								<th>
									<span>{_t.translate('page.side')}</span>
								</th>
								<th className="trade-start-heading">
									<span>{_t.translate('page.start')}</span>
								</th>
								<th className="trade-end-heading">
									<span>{_t.translate('page.end')}</span>
								</th>
								<th>
									<span>{_t.translate('page.open_price')}</span>
								</th>
								<th>
									<span>{_t.translate('page.close_price')}</span>
								</th>
								<th>
									<span>{_t.translate('page.stop_reason')}</span>
								</th>
								<th>
									<span>{_t.translate('page.outcome')}</span>
								</th>
								<th>
									<span>{_t.translate('page.platform')}</span>
								</th>
							</tr>
						</thead>
						<tbody>{trades.map(this.renderDesktopPositionLine)}</tbody>
					</table>
				</div>
				<div className="col-md-12">
					<TotalFooter profitLossInAccountCurrency={profitLossInAccountCurrency} accountCurrency={accountCurrency} />
				</div>
			</>
		);
	};

	render() {
		return (
			<PageLayout title={_t.translate('page.account_statement')} className="account-statement">
				<Mobile>{this.renderMobileAccountStatement()}</Mobile>
				<Desktop>{this.renderDesktopAccountStatement()}</Desktop>
			</PageLayout>
		);
	}
}

const mapStateToProps = (state) => ({
	startDate: state.accountHistory.startDate,
	endDate: state.accountHistory.endDate,
	trades: state.accountHistory.trades,
	profitLossInAccountCurrency: state.accountHistory.profitLossInAccountCurrency,
	accountCurrency: state.user.accountCurrency,
	instruments: state.instruments,
});

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			getAccountHistory: actions.getAccountHistory,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(PageAccountStatement);
