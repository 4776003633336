import React from 'react';
import LanguageSelect from '../global/LanguageSelect.tsx';
import { connect } from 'react-redux';
import { PageLayout } from 'components/global/PageLayout';
import { capitalize, playClickSound, releaseVersion, webpackHash } from '../../helpers';
import { actions } from 'wintrado-api';
import SoundstyleSelect from 'components/global/SoundstyleSelect';
import config from 'config';
import ChartTypeSelect from 'components/global/ChartTypeSelect';
import { NavLink } from 'react-router-dom';
import _t from 'counterpart';
import Button from 'components/Button/Button';
import AnyLink from 'components/global/AnyLink';
import { wintradoHomeUrl } from '../../constants';
import Spacer from 'components/Spacer/Spacer';

class PageSettings extends React.PureComponent {
	onSoundChange = (event) => {
		const { sound, userSaveSound } = this.props;

		event.preventDefault();
		playClickSound();
		userSaveSound(!sound);
	};

	reload = () => {
		window.location.reload(true);
	};

	onBellSoundChange = (event) => {
		const { bellSound, userSaveBellSound } = this.props;

		event.preventDefault();
		playClickSound();
		userSaveBellSound(!bellSound);
	};

	handleSoundstyleChange = (e) => {
		e.preventDefault();
		const soundstyle = e.currentTarget.getAttribute('data-soundstyle');
		this.props.setSoundstyle(soundstyle);
		playClickSound();
	};

	handleChartTypeChange = (e) => {
		e.preventDefault();
		const chartType = e.currentTarget.getAttribute('data-charttype');
		this.props.userSaveChartType(chartType);
		playClickSound();
	};

	render() {
		return (
			<PageLayout title={_t.translate('main.settings')} className="settings">
				<div className="settings__holder">
					<div className="settings__controls">
						<div className="holder">
							<div className="col-12 col-md-4 options__box">
								<div className="title">{_t.translate('page.language')}</div>
								<div className="dropDown">
									<LanguageSelect />
								</div>
							</div>
							<Spacer large x />
							<div className="col-12 col-md-4 options__box">
								<div className="title">{_t.translate('page.chart_type')}</div>
								<div className="dropDown">
									<ChartTypeSelect
										currentChartType={this.props.chartType}
										handleChangeChartType={this.handleChartTypeChange}
									/>
								</div>
							</div>
							<Spacer large x />
						</div>
						<div className="holder holder--fullwidth">
							<div className="col-12 col-md-6 options__box">
								<div className="title">{_t.translate('page.sound')}</div>
								<div className="row">
									<div className="col-6 col-md-6">
										<Button gray fullWidth withImage faded={!this.props.sound} textStart onClick={this.onSoundChange}>
											<img src={require(`../../icons/${this.props.sound ? '' : 'mute_'}speaker.svg`)} alt="sound" />
											<span>{capitalize(_t.translate(this.props.sound ? 'page.on' : 'page.off'))}</span>
										</Button>
									</div>
									<div className="col-6 col-md-6">
										<Button
											gray
											fullWidth
											withImage
											faded={!this.props.bellSound}
											textStart
											onClick={this.onBellSoundChange}
										>
											<img src={require(`../../icons/${this.props.bellSound ? '' : 'mute_'}bell.svg`)} alt="bell" />
											<span>{capitalize(_t.translate(this.props.bellSound ? 'page.on' : 'page.off'))}</span>
										</Button>
									</div>
								</div>
							</div>
							<div className="col-12 col-md-6 options__box">
								<div className="title">{_t.translate('page.soundstyle')}</div>
								<div className="dropDown">
									<SoundstyleSelect
										soundstyle={this.props.soundstyle}
										handleChangeSoundstyle={this.handleSoundstyleChange}
									/>
								</div>
							</div>
						</div>
						{Boolean(config.cashier?.profileURL) && (
							<div className="settings__user-profile">
								<div className="title">{_t.translate('page.profile')}</div>
								<NavLink to="/settings/profile">
									<Button green fullWidth>
										{_t.translate('settings.update_profile')}
									</Button>
								</NavLink>
							</div>
						)}
					</div>
				</div>
				<div className="powered-by">
					<span className="powered-by__label">{_t('main.powered_by_wintrado_platform')} &nbsp;</span>
					<AnyLink className="powered-by__link" to={wintradoHomeUrl}>
						{_t('main.learn-more')}
					</AnyLink>
				</div>
				<div className="version-container">
					<span>
						<span>{`v${releaseVersion()}`}</span>
						<span>{webpackHash()}</span>
					</span>
				</div>
			</PageLayout>
		);
	}
}

const mapStateToProps = (state) => ({
	language: state.settings.language,
	sound: state.settings.sound,
	bellSound: state.settings.bellSound,
	soundstyle: state.settings.soundstyle,
	chartType: state.settings.chartType,
});

const mapDispatchToProps = {
	userSaveSound: actions.userSaveSound,
	userSaveBellSound: actions.userSaveBellSound,
	userSaveChartType: actions.userSaveChartType,
	setSoundstyle: actions.setSoundstyle,
	displayError: actions.displayError,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageSettings);
