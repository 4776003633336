import React from 'react';
import { isMobileLayout, playClickSound } from '../../helpers';

export default class PopupMenu extends React.PureComponent {
	state = {
		showItemsMobile: false,
	};

	onClicked = (e) => {
		e.preventDefault();
		if (isMobileLayout()) {
			playClickSound();
			this.setState({
				showItemsMobile: true,
			});
		}
	};

	onClickedItem = (e) => {
		e.stopPropagation();
		if (
			e.target.tagName !== 'UL' &&
			!e.target.classList.contains('disabled') &&
			!e.target.parentElement.classList.contains('disabled')
		) {
			playClickSound();
			if (isMobileLayout()) {
				this.setState({
					showItemsMobile: false,
				});
			}
		}
	};

	handleDismiss = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (this.props.onDismiss) {
			this.props.onDismiss(e);
		} else {
			this.setState({
				showItemsMobile: false,
			});
		}
	};

	render() {
		const {
			mobileVisible = true,
			label,
			currentValue,
			items,
			className,
			mobileItemsVisible = false,
		} = this.props;
		const { showItemsMobile } = this.state;

		return (
			<>
				{(showItemsMobile || mobileItemsVisible) && (
					<div className="popup-menu-overlay d-hide m-show" onClick={this.handleDismiss} />
				)}
				<div className={`popup-menu${mobileVisible ? '' : ' m-hide'} ${className}`}>
					<a href="#" onClick={this.onClicked}>
						<span>
							<p>{label}</p>
							<div>
								<strong>{currentValue}</strong>
							</div>
						</span>
						<ul className={showItemsMobile || mobileItemsVisible ? 'd-hide m-show' : ''} onClick={this.onClickedItem}>
							{items}
						</ul>
					</a>
				</div>
			</>
		);
	}
}
