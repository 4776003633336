import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import PopupMenu from 'components/global/PopupMenu';
import classNames from 'classnames';
import _t from 'counterpart';
import { actions, instrumentsHelpers, selectors } from 'wintrado-api';
import { useAppDispatch, useAppSelector } from '../../storeHooks';
import { getDurationLabel, isSet } from '../../helpers';
const MainMenuBottomDuration = () => {
    const activeInstrumentWithValidTradingIntervals = useAppSelector(selectors.activeInstrumentWithIntervalsSelector);
    const durationSelected = useAppSelector((state) => state.tradingScreen.durationSelected);
    const positionDurations = useAppSelector((state) => { var _a; return (_a = state.config) === null || _a === void 0 ? void 0 : _a.positionDurations; });
    const dispatch = useAppDispatch();
    if (activeInstrumentWithValidTradingIntervals === null) {
        throw new Error('Active instrument not loaded');
    }
    if (durationSelected === null) {
        throw new Error('Duration selected not loaded');
    }
    if (!isSet(positionDurations)) {
        throw new Error('Position durations not loaded');
    }
    const sortedPositionDurations = [...positionDurations].sort((a, b) => b - a);
    const buttons = useMemo(() => sortedPositionDurations.map((duration) => {
        const validDuration = duration === 0
            ? true
            : instrumentsHelpers.isValidTradingDurationForInstrument(activeInstrumentWithValidTradingIntervals, duration);
        return (_jsx("li", Object.assign({ onClick: () => {
                if (validDuration) {
                    dispatch(actions.selectTradeDuration(duration));
                }
            }, className: classNames({ disabled: !validDuration }) }, { children: _jsx("div", Object.assign({ className: "duration-label" }, { children: _jsx("strong", { children: getDurationLabel(duration) }) })) }), duration.toString()));
    }), [activeInstrumentWithValidTradingIntervals, dispatch, sortedPositionDurations]);
    return (_jsx(PopupMenu, { className: "period", label: _t.translate('main.duration'), currentValue: getDurationLabel(durationSelected), items: buttons }));
};
export default MainMenuBottomDuration;
