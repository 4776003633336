import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
const ModalCloseButton = ({ handleClose, dark }) => {
    const handleClick = (e) => {
        e.preventDefault();
        handleClose();
    };
    return (_jsx("button", Object.assign({ type: "button", className: classNames('modal-close-button', { 'modal-close-button--dark': dark }), "aria-label": "Close modal", onClick: handleClick }, { children: _jsx("span", Object.assign({ "aria-hidden": "true" }, { children: "\u00D7" })) })));
};
ModalCloseButton.defaultProps = {
    dark: false,
};
export default ModalCloseButton;
