import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import _t from 'counterpart';
import Button from 'components/Button/Button';

const successIcon = require('../../icons/checkmark.svg');
const errorIcon = require('../../icons/error.svg');

class PopupNotifications extends React.PureComponent {
	state = {
		alert: null,
	};

	showAlert = (alert) => {
		this.addAlertShownToBody();
		this.setState({
			alert,
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null,
		});
		this.hideAlertShownToBody();
	};

	addAlertShownToBody = () => {
		document.querySelector('body').classList.add('alert-shown');
	};

	hideAlertShownToBody = () => {
		document.querySelector('body').classList.remove('alert-shown');
	};

	handlePrimaryButtonClick = () => {
		this.hideAlert();
		if (this.state.alert.buttonOnClick) {
			this.state.alert.buttonOnClick();
		}
	};

	renderAlert = () => {
		const { alert } = this.state;
		const { type, title, subtitle, buttonLabel, buttonOnClick, showCancelButton, customIcon } = alert;
		return (
			<CSSTransition key="alert" className="popup-alert" timeout={600}>
				<div>
					<div className="alert-wrapper">
						<button className="close" onClick={this.hideAlert}>
							×
						</button>
						<div className={`icon-container ${customIcon ? '' : type}`}>
							{customIcon && <img src={customIcon} alt="icon" />}
							{!customIcon && <img src={type === 'success' ? successIcon : errorIcon} alt="icon" />}
						</div>
						{title && <div className="title" dangerouslySetInnerHTML={{ __html: title }} />}
						{subtitle && <div className="subtitle" dangerouslySetInnerHTML={{ __html: subtitle }} />}
						<div className="button-wrapper">
							<button className={`action ${type}`} onClick={this.handlePrimaryButtonClick}>
								{buttonLabel}
							</button>
							{showCancelButton && (
								<Button gray onClick={this.hideAlert}>
									{_t.translate('main.cancel')}
								</Button>
							)}
						</div>
					</div>
				</div>
			</CSSTransition>
		);
	};

	render() {
		const { alert } = this.state;
		return (
			<div id="popup-alert-wrapper">
				<TransitionGroup>{alert !== null && this.renderAlert()}</TransitionGroup>
			</div>
		);
	}
}

export default PopupNotifications;
