import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import ProgressBar from '../ProgressBar';
import { selectors } from 'wintrado-api';
import { formatSeconds } from '../../helpers';
import { useAppSelector } from '../../storeHooks';
const _t = require('counterpart');
const PositionProgressBar = ({ positionId }) => {
    const positionTimeLeft = useAppSelector((state) => selectors.positionTimeLeftSelector(state, positionId));
    const duration = useAppSelector((state) => { var _a; return (_a = state.positions[positionId]) === null || _a === void 0 ? void 0 : _a.duration; });
    const getTimeLeft = () => {
        if (positionTimeLeft) {
            return positionTimeLeft;
        }
        return {
            timeLeft: duration,
            timeLeftPercentage: 0,
        };
    };
    const { timeLeft, timeLeftPercentage } = getTimeLeft();
    return _jsx(ProgressBar, { value: timeLeft === Infinity ? _t.translate('main.unlimited') : formatSeconds(timeLeft), percent: timeLeftPercentage });
};
export default React.memo(PositionProgressBar);
