import React from 'react';
import { useSelector } from 'react-redux';

export default function MessagesIcon() {
	const unreadCount = useSelector((state) => state.broker.messages.unreadCount);
	const unread = unreadCount > 0;
	return (
		<div className="messages-icon">
			<svg fill="none" height="16" viewBox="0 0 18 16" width="18" xmlns="http://www.w3.org/2000/svg">
				<mask id="circle">
					<rect x="0" y="0" fill="white" width="18" height="16" />
					{unread && <circle cx="16" cy="2" fill="black" r="6" />}
				</mask>
				<g mask="url(#circle)">
					<g stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5">
						<path d="m1.5 5 7.5 4 7.5-4" />
						<path d="m15.5 14.5h-13c-.552 0-1-.448-1-1v-11c0-.552.448-1 1-1h13c.552 0 1 .448 1 1v11c0 .552-.448 1-1 1z" />
					</g>
				</g>
			</svg>
			{unread && <div className="messages-icon__unread-badge" />}
		</div>
	);
}
