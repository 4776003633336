var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Desktop, Mobile } from 'utils/responsiveHelpers';
import SidebarLeft from 'components/main/SidebarLeft';
import Header from 'components/main/Header';
import ChartWrapper from 'components/ChartWrapper/ChartWrapper';
import RightWidgetWrapper from 'components/main/RightWidgetWrapper';
import MainPositionsList from 'components/main/PositionsList';
import TradingBar from 'components/main/TradingBar';
import AnimatedSwitch from 'components/main/AnimatedSwitch';
import { Route, useHistory } from 'react-router-dom';
import PageProfile from 'components/page/UpdateProfileInfo';
import PageSettings from 'components/page/Settings';
import PageDeposit from 'components/page/Deposit';
import PageWithdraw from 'components/page/Withdraw';
import config from 'config';
import PageUploadDocuments from 'components/page/UploadDocuments';
import PageAccountStatement from 'components/page/AccountStatement';
import PageLeaderboard from 'components/page/PageLeaderboard';
import CoinsExchange from 'components/page/CoinsExchange/CoinsExchange';
import PageMetatrader from 'components/page/Metatrader';
import PageMessageInner from 'components/page/MessageInner';
import PageMessages from 'components/page/Messages';
import PageCreateTradingStrategy from 'components/copyTrading/CreateTradingStrategy';
import PageCopyTradingStrategy from 'components/copyTrading/CopyTradingStrategy';
import PageTradingStrategy from 'components/copyTrading/TradingStrategy';
import PageCopyTrading from 'components/copyTrading/CopyTrading';
import PageLoyaltyProfile from 'components/page/LoyaltyProfile/LoyaltyProfile';
import SidebarRight from 'components/main/SidebarRight';
import TrackingPixel from 'components/global/TrackingPixel';
import ModalLogout from 'components/modal/Logout';
import ModalDepositWithdraw from 'components/modal/DepositWithdraw';
import ModalLowMargin from 'components/modal/LowMargin';
import ModalStopOut from 'components/modal/StopOut';
import ModalMarketClosedError from 'components/modal/MarketClosedError';
import SoundbankLoader from 'components/SoundbankLoader';
import MobileSideDrawer from 'components/main/MobileSideDrawer';
import MobileInstrumentsDrawer from 'components/main/MobileInstrumentsDrawer';
import TrophyAnimation from 'components/TrophyAnimation';
import ModalCompetition from 'components/modal/ModalCompetition';
import ModalInsufficientFunds from 'components/modal/ModalInsufficientFunds';
import ModalInstrumentTrendExplainer from 'components/modal/ModalInstrumentTrendExplainer';
import { hideZendeskBubble, isStandalone, playClickSound } from '../helpers';
import $ from 'jquery';
import { useMediaQuery } from 'react-responsive';
import { useAppDispatch, useAppSelector } from '../storeHooks';
import { actions, selectors } from 'wintrado-api';
const MainGrid = () => {
    const [instrumentSliderVisible, setInstrumentSliderVisible] = useState(false);
    const userId = useAppSelector((state) => state.user.id);
    const supportsUserUpdate = useAppSelector(selectors.supportsUserUpdateSelector);
    const supportsCopytrading = useAppSelector(selectors.supportsCopytrading);
    const activeInstrument = useAppSelector((state) => state.activeInstrument);
    const rightSideWidget = useAppSelector((state) => state.tradingScreen.rightSideWidget);
    const dispatch = useAppDispatch();
    const history = useHistory();
    const shouldShowTwoRightWidgets = useMediaQuery({
        query: '(min-width: 1501px)',
    });
    useEffect(() => {
        $('[data-toggle="tooltip"]').tooltip({
            trigger: 'hover',
            boundary: 'window',
        });
        hideZendeskBubble();
        updateUserInfo();
        handleMemoryRouterAddressNavigation();
    });
    const handleMemoryRouterAddressNavigation = () => {
        if (window.location.pathname !== history.location.pathname) {
            history.push(window.location.pathname);
            window.history.replaceState({
                page: 'page',
            }, document.title, '/');
        }
    };
    const updateUserInfo = () => __awaiter(void 0, void 0, void 0, function* () {
        if (supportsUserUpdate) {
            dispatch(actions.updateUser({
                standalone: isStandalone(),
            }));
        }
    });
    const isTradingScreenVisible = useMemo(() => history.location.pathname === '/', [history]);
    const setRightSideWidget = useCallback((widget) => {
        if (!isTradingScreenVisible) {
            history.push('/');
        }
        dispatch(actions.setRightSideWidget(widget));
    }, [dispatch, history, isTradingScreenVisible]);
    const onClickLogout = useCallback((e) => {
        e.stopPropagation();
        playClickSound();
        dispatch(actions.logoff({ userRequested: true }));
    }, [dispatch]);
    const onBackToPositions = useCallback((e = null) => {
        if (e) {
            e.preventDefault();
        }
        setRightSideWidget('positions');
        playClickSound();
    }, [setRightSideWidget]);
    const openInstrumentsSlider = useCallback(() => {
        setInstrumentSliderVisible(true);
    }, []);
    const closeInstrumentsSlider = useCallback(() => {
        setInstrumentSliderVisible(false);
    }, []);
    const switchToMarkets = useCallback(() => {
        setRightSideWidget('markets');
    }, [setRightSideWidget]);
    if (!activeInstrument) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(Desktop, { children: _jsx(SidebarLeft, {}) }), _jsxs("section", Object.assign({ id: "mainContainer", className: history.location.pathname === '/' ? 'trading-screen' : '' }, { children: [_jsx(Header, { openInstrumentsSlider: openInstrumentsSlider, setRightSideWidget: setRightSideWidget }), _jsxs("div", Object.assign({ id: "mainContent" }, { children: [_jsx(ChartWrapper, { openInstrumentsSlider: openInstrumentsSlider, switchToMarkets: switchToMarkets }), _jsx(Desktop, { children: _jsx(RightWidgetWrapper, { displayTwoRightWidgets: shouldShowTwoRightWidgets, rightSideWidget: rightSideWidget, onBackToPositions: onBackToPositions }) }), _jsx(Mobile, { children: _jsx(MainPositionsList, {}) })] })), _jsx(TradingBar, {}), _jsxs(AnimatedSwitch, { children: [_jsx(Route, Object.assign({ path: "/settings/profile" }, { children: _jsx(PageProfile, {}) })), _jsx(Route, Object.assign({ path: "/settings" }, { children: _jsx(PageSettings, {}) })), _jsx(Route, Object.assign({ path: "/deposit" }, { children: _jsx(PageDeposit, {}) })), _jsx(Route, Object.assign({ path: "/withdraw" }, { children: _jsx(PageWithdraw, {}) })), config.cashier && config.cashier.uploadDocumentsURL && (_jsx(Route, Object.assign({ path: "/upload-documents" }, { children: _jsx(PageUploadDocuments, {}) }))), _jsx(Route, Object.assign({ path: "/account-statement" }, { children: _jsx(PageAccountStatement, {}) })), _jsx(Route, Object.assign({ path: "/leaderboard" }, { children: _jsx(PageLeaderboard, {}) })), _jsx(Route, Object.assign({ path: "/coins" }, { children: _jsx(CoinsExchange, {}) })), _jsx(Route, Object.assign({ path: "/metatrader" }, { children: _jsx(PageMetatrader, {}) })), _jsx(Route, Object.assign({ path: "/messages/:id" }, { children: _jsx(PageMessageInner, {}) })), _jsx(Route, Object.assign({ exact: true, path: "/messages" }, { children: _jsx(PageMessages, {}) })), supportsCopytrading && [
                                _jsx(Route, Object.assign({ path: "/copy-trading/strategies/create" }, { children: _jsx(PageCreateTradingStrategy, {}) }), "/copy-trading/strategies/create"),
                                _jsx(Route, Object.assign({ path: "/copy-trading/strategies/:id/copy" }, { children: _jsx(PageCopyTradingStrategy, {}) }), "/copy-trading/strategies/:id/copy"),
                                _jsx(Route, Object.assign({ path: "/copy-trading/strategies/:id" }, { children: _jsx(PageTradingStrategy, {}) }), "/copy-trading/strategies/:id"),
                                _jsx(Route, Object.assign({ path: "/copy-trading/strategies" }, { children: _jsx(PageCopyTrading, {}) }), "/copy-trading/strategies"),
                            ], _jsx(Route, Object.assign({ path: "/loyalty" }, { children: _jsx(PageLoyaltyProfile, {}) }))] })] })), _jsx(Desktop, { children: _jsx(SidebarRight, { displayTwoRightWidgets: shouldShowTwoRightWidgets, setRightSideWidget: setRightSideWidget, isTradingScreenVisible: isTradingScreenVisible }) }), _jsx(TrackingPixel, {}), _jsx(ModalLogout, { onClickLogout: onClickLogout }), _jsx(ModalDepositWithdraw, {}), _jsx(ModalLowMargin, {}), _jsx(ModalStopOut, {}), _jsx(ModalMarketClosedError, {}), _jsx(SoundbankLoader, {}), _jsx(MobileSideDrawer, {}), _jsx(MobileInstrumentsDrawer, { open: instrumentSliderVisible, closeDrawer: closeInstrumentsSlider }), _jsx(TrophyAnimation, {}), _jsx(ModalCompetition, { setRightSideWidget: setRightSideWidget }), _jsx(ModalInsufficientFunds, {}), _jsx(ModalInstrumentTrendExplainer, {})] }));
};
export default React.memo(MainGrid);
