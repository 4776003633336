import { jsx as _jsx } from "react/jsx-runtime";
import * as Sentry from "@sentry/react";
import { webpackHash } from "./helpers";
import config from "config";
import "core-js/fn/object/assign";
import "bootstrap";
import { createRoot } from "react-dom/client";
import App from "components/App";
import moment from "moment";
import "moment/min/locales";
import { actions, Analytics, setConfig, store } from "wintrado-api";
import registerServiceWorker from "./registerServiceWorker";
import posthog from "posthog-js";
import { BrowserRouter } from "react-router-dom";
import { initializeTranslations } from "utils/i18n";
import { getNavigatorLanguage } from "utils/locales";
import WebFont from "webfontloader";
// Inject configuration wintrado-api
setConfig(config);
initializeTranslations();
if (process.env.NODE_ENV !== 'development') {
    const sentryIntegrations = [];
    if (config.posthogKey && config.posthogHost) {
        Analytics.init(config.posthogKey, { api_host: config.posthogHost });
        sentryIntegrations.push(new posthog.SentryIntegration(posthog, config.sentryOrganization, config.sentryProjectId));
    }
    Sentry.init({
        normalizeDepth: 10,
        dsn: config.sentryUrl,
        environment: config.appEnv,
        release: process.env.SENTRY_RELEASE,
        // @ts-ignore
        integrations: sentryIntegrations,
    });
    Sentry.setTag('webpackHash', webpackHash());
}
moment.locale([getNavigatorLanguage(), 'en']);
WebFont.load({ google: { families: ['Material+Icons'] } });
function updateOnlineStatus() {
    const isOnline = navigator.onLine;
    store.dispatch(actions.setIsOnline(isOnline));
}
window.addEventListener('load', updateOnlineStatus);
window.addEventListener('online', updateOnlineStatus);
window.addEventListener('offline', updateOnlineStatus);
// Set favicon based on brand
const head = document.getElementsByTagName('head')[0];
['192x192', '96x96', '32x32', '16x16'].forEach((size) => {
    const link = document.createElement('link');
    link.rel = 'icon';
    link.href = `/favicons/${__BRAND__}/favicon-${size}.png`;
    link.type = 'image/png';
    // @ts-ignore
    link.sizes = size;
    head === null || head === void 0 ? void 0 : head.appendChild(link);
});
// Render the main component into the dom
const container = document.getElementById('app');
const root = createRoot(container);
root.render(_jsx(BrowserRouter, { children: _jsx(App, {}) }));
registerServiceWorker();
