import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from "react";
import tradingUp from "../../icons/trending-up.svg";
import tradingDown from "../../icons/trending-down.svg";
import _t from "counterpart";
import { Desktop } from "utils/responsiveHelpers";
import DesktopInstrumentTrendIndicator from "components/DesktopInstrumentTrendIndicator";
import Button from "components/Button/Button";
import { useAppDispatch, useAppSelector } from "../../storeHooks";
import { actions, instrumentsHelpers, PositionSide, selectors, gatewayService } from "wintrado-api";
import { extractErrorMessage, playClickSound } from "../../helpers";
import $ from "jquery";
import { useMutation } from "react-query";
const MenuBottomControls = () => {
    var _a, _b;
    const activeInstrumentWithIntervals = useAppSelector(state => selectors.activeInstrumentWithIntervalsSelector(state));
    const activePositionId = useAppSelector(state => state.activePositionId);
    const isActivePositionCloseable = useAppSelector(state => selectors.isActivePositionCloseable(state));
    const closingAllPositions = useAppSelector(state => state.closingAllPositions);
    const investSelected = useAppSelector(state => state.tradingScreen.investSelected);
    const durationSelected = useAppSelector(state => state.tradingScreen.durationSelected);
    const profitLossSelected = useAppSelector(state => state.tradingScreen.profitLossSelected);
    const closingPositionsIds = useAppSelector(state => state.closingPositionsIds);
    const tradingEnabled = useAppSelector(state => state.tradingEnabled);
    const dispatch = useAppDispatch();
    const shouldEnableTradingButtons = useMemo(() => tradingEnabled && Boolean(activeInstrumentWithIntervals === null || activeInstrumentWithIntervals === void 0 ? void 0 : activeInstrumentWithIntervals.validTradingIntervals.current), [activeInstrumentWithIntervals === null || activeInstrumentWithIntervals === void 0 ? void 0 : activeInstrumentWithIntervals.validTradingIntervals, tradingEnabled]);
    const placeOrderMutation = useMutation({
        mutationFn: (data) => gatewayService.placeOrder(data),
        onSuccess: (data) => {
            if (data.position) {
                dispatch(actions.addPosition(data.position));
            }
        },
        onError: (error) => {
            dispatch(actions.displayError(_t('error.failed_to_open_position'), extractErrorMessage(error)));
        }
    });
    if (activeInstrumentWithIntervals === null || durationSelected === null || profitLossSelected === null || investSelected === null) {
        // TODO fix redux types
        return null;
    }
    const validateMarketOpen = () => {
        if (durationSelected !== Infinity &&
            (!activeInstrumentWithIntervals.validTradingIntervals.current ||
                !instrumentsHelpers.isValidTradingDurationForInstrument(activeInstrumentWithIntervals, durationSelected))) {
            showMarketClosedError();
            return false;
        }
        return true;
    };
    const showMarketClosedError = () => {
        const modal = $("#market_closed_error_modal");
        modal.modal("show");
    };
    const onClickSell = (e) => {
        e.preventDefault();
        if (!closingAllPositions) {
            if (!validateMarketOpen()) {
                return;
            }
            placeOrderMutation.mutate({
                type: "market",
                symbol: activeInstrumentWithIntervals.symbol,
                side: PositionSide.SELL,
                size: profitLossSelected,
                duration: durationSelected === 0 ? null : durationSelected,
                invest: investSelected
            });
            playClickSound();
        }
    };
    const onClickStop = (e) => {
        e.preventDefault();
        if (activePositionId && closingPositionsIds.length === 0) {
            dispatch(actions.positionsCloseSingle(activePositionId));
            playClickSound();
        }
    };
    const onClickBuy = (e) => {
        if (!closingAllPositions) {
            e.preventDefault();
            if (!validateMarketOpen()) {
                return;
            }
            placeOrderMutation.mutate({
                type: "market",
                symbol: activeInstrumentWithIntervals.symbol,
                side: PositionSide.BUY,
                size: profitLossSelected,
                duration: durationSelected === 0 ? null : durationSelected,
                invest: investSelected
            });
            playClickSound();
        }
    };
    return (_jsx("div", Object.assign({ className: "trading-buttons", id: "tradingButtons" }, { children: _jsxs("div", Object.assign({ className: "trading-buttons__holder" }, { children: [_jsxs(Button, Object.assign({ id: "tradingButtonSell", red: true, disabled: !shouldEnableTradingButtons, onClick: onClickSell, loading: placeOrderMutation.isLoading && ((_a = placeOrderMutation.variables) === null || _a === void 0 ? void 0 : _a.side) === PositionSide.SELL }, { children: [_jsx("span", Object.assign({ className: "title" }, { children: _t.translate("main.sell") })), _jsx("span", Object.assign({ className: "icons-trade" }, { children: _jsx("img", { src: tradingDown, alt: "Buy" }) }))] })), _jsx(Button, Object.assign({ id: "tradingButtonStop", gray: true, disabled: !isActivePositionCloseable || closingPositionsIds.length !== 0, onClick: onClickStop }, { children: _t.translate("main.stop") })), _jsxs(Button, Object.assign({ id: "tradingButtonBuy", green: true, disabled: !shouldEnableTradingButtons, onClick: onClickBuy, loading: placeOrderMutation.isLoading && ((_b = placeOrderMutation.variables) === null || _b === void 0 ? void 0 : _b.side) === PositionSide.BUY }, { children: [_jsxs("span", Object.assign({ className: "title" }, { children: [_t.translate("main.buy"), " "] })), _jsx("span", Object.assign({ className: "icons-trade" }, { children: _jsx("img", { src: tradingUp, alt: "Sell" }) }))] })), _jsx(Desktop, { children: _jsx(DesktopInstrumentTrendIndicator, {}) })] })) })));
};
export default React.memo(MenuBottomControls);
