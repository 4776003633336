import React from 'react';
import { capitalize, playClickSound } from '../../helpers';

const chartTypes = ['simple', 'candle'];

export default class ChartTypeSelect extends React.Component {
	render() {
		const { currentChartType, handleChangeChartType } = this.props;
		return (
			<div className="dropupContainer">
				<button
					onClick={playClickSound}
					type="button"
					className="btn dropdown-toggle"
					data-toggle="dropdown"
					aria-haspopup="true"
					aria-expanded="false"
				>
					<span> {capitalize(currentChartType)} </span>
				</button>
				<div className="dropdown-menu wt-scroll chart-type">
					{chartTypes.map((chartType) => {
						return (
							<div key={chartType}>
								<a className="dropdown-item" href="#" data-charttype={chartType} onClick={handleChangeChartType}>
									<span> {capitalize(chartType)} </span>
								</a>
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}
