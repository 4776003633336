// In production, we register a service worker to serve assets from local cache.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.

import PopupNotificationManager from 'components/PopupNotification/PopupNotificationManager';

function updateServiceWorker(newSW) {
	return (e) => {
		e.preventDefault();
		if (newSW) {
			newSW.postMessage('skipWaiting');
		}
		window.location.reload();
	};
}

function hideRefreshPopup(e) {
	e.preventDefault();
	PopupNotificationManager.hideNotification('refresh');
}

function registerVisibilityChangedUpdater(registration) {
	window.document.addEventListener('visibilitychange', () => {
		if (!document.hidden) {
			registration.update();
		}
	});
}

function showRefreshNotification(registration) {
	console.log('New content is available');

	PopupNotificationManager.showNotification({
		id: 'refresh',
		primaryButtonTitleKey: 'main.refresh',
		onPrimaryButtonClicked: updateServiceWorker(registration),
		secondaryButtonTitleKey: 'main.cancel',
		onSecondaryButtonClicked: hideRefreshPopup,
		descriptionKey: 'main.new_content',
	});
}

export default function register() {
	// Register the service worker
	if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
		window.addEventListener('load', () => {
      const swUrl = 'OneSignalSDKWorker.js';
			navigator.serviceWorker
				.register(swUrl, {
					updateViaCache: 'none',
				})
				.then((registration) => {
					// in case updatefound event was missed
					if (registration.waiting) {
						showRefreshNotification(registration.waiting);
					}

					registerVisibilityChangedUpdater(registration);

					registration.onupdatefound = () => {
						const newSW = registration.installing;
						newSW.onstatechange = () => {
							if (registration.waiting) {
								if (navigator.serviceWorker.controller) {
									showRefreshNotification(newSW);
								}
							}
						};
					};
				})
				.catch((error) => {
					console.error('Error during service worker registration:', error);
				});
		});
	}
}

export function unregister() {
	if ('serviceWorker' in navigator) {
		navigator.serviceWorker.ready.then((registration) => {
			registration.unregister();
		});
	}
}
