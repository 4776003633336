import React from 'react';
import { bindActionCreators } from 'redux';
import { actions, selectors } from 'wintrado-api';
import { connect } from 'react-redux';
import _t from 'counterpart';
import Button from 'components/Button/Button';

class MarketClosedError extends React.Component {
	render() {
		const { activeInstrumentWithIntervals } = this.props;
		if (!activeInstrumentWithIntervals) return null;

		const closedUntil = activeInstrumentWithIntervals.validTradingIntervals.next.start.toLocaleString(_t.getLocale(), {
			weekday: 'long',
			hour: '2-digit',
			minute: '2-digit',
		});

		return (
			<div
				id="market_closed_error_modal"
				className="modal fade bd-example-modal-sm"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="mySmallModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						{_t.translate('modal.market_closed_error', {
							instrument: activeInstrumentWithIntervals.symbol,
							closed_until: closedUntil,
						})}
						<div className="modal_btns">
							<div className="holder">
								<Button green inline data-dismiss="modal" aria-label="Close">
									{_t.translate('modal.okay')}
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		activeInstrumentWithIntervals: selectors.activeInstrumentWithIntervalsSelector(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			logoff: actions.logoff,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketClosedError);
