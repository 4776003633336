import { ColorType, createChart, LineStyle, TickMarkType } from 'lightweight-charts';
import _t from 'counterpart';
import { decimalPlacesToPrecisionString } from '../helpers';
import { PositionSide } from 'wintrado-api';
export const chartSettings = {
    backgroundColor: '#1e2024',
    lineColor: '#12AAEB',
    areaTopColor: 'rgba(18, 170, 235, 1)',
    areaBottomColor: 'rgba(18, 170, 235, 0.1)',
    gridColor: '#343434',
    textColor: '#888888',
    buyColor: '#3E8C3B',
    sellColor: '#F60057',
    lastValueLineColor: '#12AAEB',
    priceLineWidth: 1,
};
export const initializeChart = (containerElement) => {
    return createChart(containerElement, {
        layout: {
            background: {
                type: ColorType.Solid,
                color: chartSettings.backgroundColor,
            },
            textColor: chartSettings.textColor,
            fontFamily: 'Equip, sans-serif',
        },
        localization: {
            locale: _t.getLocale(),
            timeFormatter: (timestamp, locale) => {
                const date = new Date(timestamp * 1000);
                const formattedDate = date.toLocaleString(locale, {
                    day: '2-digit',
                    month: 'short',
                    year: '2-digit',
                });
                const formattedTime = date.toLocaleTimeString(locale);
                return `${formattedDate}, ${formattedTime}`;
            },
        },
        timeScale: {
            timeVisible: true,
            secondsVisible: true,
            fixRightEdge: true,
            fixLeftEdge: true,
            tickMarkFormatter: (time, tickMarkType, locale) => {
                const date = new Date(time * 1000);
                let result;
                switch (tickMarkType) {
                    case TickMarkType.Year:
                        result = date.toLocaleString(locale, { year: 'numeric' });
                        break;
                    case TickMarkType.Month:
                        result = date.toLocaleString(locale, { month: 'short' });
                        break;
                    case TickMarkType.DayOfMonth:
                        result = date.toLocaleString(locale, { day: 'numeric' });
                        break;
                    case TickMarkType.Time:
                        result = date.toLocaleString(locale, { hour: 'numeric', minute: 'numeric' });
                        break;
                    case TickMarkType.TimeWithSeconds:
                        result = date.toLocaleString(locale, { hour: 'numeric', minute: 'numeric', second: 'numeric' });
                        break;
                    default:
                        throw new Error('Invalid TickMarkType');
                }
                return result;
            },
        },
        rightPriceScale: {
            visible: true,
        },
        grid: {
            vertLines: {
                color: chartSettings.gridColor,
            },
            horzLines: {
                color: chartSettings.gridColor,
            },
        },
        width: containerElement.clientWidth,
        height: containerElement.clientHeight,
    });
};
export const addCandlestickSeriesToChart = (chart, decimals) => {
    return chart.addCandlestickSeries({
        upColor: chartSettings.buyColor,
        downColor: chartSettings.sellColor,
        wickUpColor: chartSettings.buyColor,
        wickDownColor: chartSettings.sellColor,
        priceLineColor: chartSettings.lastValueLineColor,
        lastValueVisible: false,
        priceLineVisible: false,
        borderVisible: false,
        priceFormat: {
            type: 'price',
            precision: decimals,
            minMove: decimalPlacesToPrecisionString(decimals),
        },
    });
};
export const addAreaSeriesToChart = (chart, decimals) => {
    return chart.addAreaSeries({
        lineColor: chartSettings.lineColor,
        topColor: chartSettings.areaTopColor,
        lineWidth: 1,
        bottomColor: chartSettings.areaBottomColor,
        lastValueVisible: false,
        priceLineVisible: false,
        priceFormat: {
            type: 'price',
            precision: decimals,
            minMove: decimalPlacesToPrecisionString(decimals),
        },
    });
};
export const activePositionToPriceLineConfig = (side, openPrice) => ({
    price: openPrice,
    color: side === PositionSide.SELL ? chartSettings.sellColor : chartSettings.buyColor,
    lineWidth: chartSettings.priceLineWidth,
    lineStyle: LineStyle.Dotted,
    lineVisible: true,
    axisLabelVisible: true,
    title: '',
});
export const lastValueToPriceLineConfig = (value) => ({
    price: value,
    color: chartSettings.lastValueLineColor,
    lineWidth: chartSettings.priceLineWidth,
    lineStyle: LineStyle.Dotted,
    lineVisible: true,
    axisLabelVisible: true,
    title: '',
});
