import React from 'react';
import classNames from 'classnames';

const smallSilverCup = require('../../icons/small-silver-cup.svg');
const smallBronzeCup = require('../../icons/small-bronze-cup.svg');
const smallGoldCup = require('../../icons/small-gold-cup.svg');
const trendingUpIcon = require('../../icons/up-triangle.svg');
const trendingDownIcon = require('../../icons/down-triangle.svg');

const RANK_CHANGES = {
	UP: 'up',
	DOWN: 'down',
};

export class LeaderboardListRow extends React.PureComponent {
	getCup = () => {
		const { rank, competition } = this.props;
		if (rank === 1) return smallGoldCup;
		if (rank === 2 && competition.secondPrizeInUSD !== null) return smallSilverCup;
		if (rank === 3 && competition.thirdPrizeInUSD !== null) return smallBronzeCup;
		return null;
	};

	getRankChanges = () => {
		const { rank, previousRank, isCurrentUser } = this.props;
		if (!isCurrentUser || rank === previousRank) {
			return null;
		}
		if (rank > previousRank) {
			return RANK_CHANGES.UP;
		}
		if (rank < previousRank) {
			return RANK_CHANGES.DOWN;
		}
	};

	getChangeIcon = (rankChange) => {
		if (!rankChange) {
			return null;
		}
		if (rankChange === RANK_CHANGES.UP) {
			return trendingUpIcon;
		}
		if (rankChange === RANK_CHANGES.DOWN) {
			return trendingDownIcon;
		}
	};

	render() {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { rank, username, score, isCurrentUser, competition, previousRank, ...rest } = this.props;
		const rankChange = this.getRankChanges();
		const changeIcon = this.getChangeIcon(rankChange);
		const shouldShowCup = this.getCup() !== null;

		return (
			<div
				className={classNames('leaderboard-list-row', { top: shouldShowCup, 'current-user': isCurrentUser })}
				{...rest}
			>
				{shouldShowCup && (
					<div className="cup-wrapper">
						<img src={this.getCup()} alt="cup" className="cup" />
					</div>
				)}
				{!shouldShowCup && <span className={classNames('rank', rankChange)}>{rank}</span>}
				<div className="rank-icon-wrapper">{Boolean(changeIcon) && <img src={changeIcon} alt={rankChange} />}</div>

				<span className="usernameText">{username}</span>
				<span className="scoreText">{parseFloat(score).toFixed(2)}</span>
			</div>
		);
	}
}
