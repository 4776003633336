import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Modal from 'react-modal';
import _t from 'counterpart';
import InsufficientBalanceAmounts from '../InsufficientBalanceAmounts';
import { useAppDispatch, useAppSelector } from '../../storeHooks';
import { hideInsufficientFundsModal } from 'wintrado-api/src/actions';
import AnyLink from '../global/AnyLink';
import ModalCloseButton from '../ModalCloseButton';
import ModalFooterButtons from '../ModalFooterButtons';
import { freeMarginSelector } from 'wintrado-api/src/selectors';
import Button from 'components/Button/Button';
const ModalInsufficientFunds = () => {
    const { accountCurrency } = useAppSelector((state) => state.user);
    const freeMargin = useAppSelector(freeMarginSelector);
    const { shown, requiredAmount } = useAppSelector((state) => state.modals.insufficientFunds);
    const dispatch = useAppDispatch();
    const handleCloseModal = () => {
        dispatch(hideInsufficientFundsModal());
    };
    return (_jsxs(Modal, Object.assign({ isOpen: shown, onRequestClose: handleCloseModal, className: "ReactModal__Content ReactModal__Content--light", overlayClassName: "ReactModal__Overlay" }, { children: [_jsx(ModalCloseButton, { handleClose: handleCloseModal, dark: true }), _jsxs("div", Object.assign({ className: "insufficient-funds" }, { children: [_jsx("h2", Object.assign({ className: "title" }, { children: _t.translate('modal.insufficient_funds.title') })), _jsx("p", Object.assign({ className: "description" }, { children: _t.translate('modal.insufficient_funds.description') })), _jsx("div", Object.assign({ className: "balance-amounts-wrapper" }, { children: _jsx(InsufficientBalanceAmounts, { currentFreeMargin: freeMargin, requiredAmount: requiredAmount, currency: accountCurrency }) })), _jsx(ModalFooterButtons, { children: _jsx(AnyLink, Object.assign({ to: "/deposit", onClick: handleCloseModal }, { children: _jsx(Button, Object.assign({ green: true }, { children: _t.translate('page.deposit') })) })) })] }))] })));
};
export default ModalInsufficientFunds;
