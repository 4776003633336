import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import styles from './WalletPicker.module.scss';
import backIcon from '../../icons/back-icon.svg';
import _t from 'counterpart';
import { useQuery } from 'react-query';
import { crmService } from 'wintrado-api';
import InlineLoader from 'components/InlineLoader';
import BalancePill from 'components/BalancePill/BalancePill';
import { requestOAuthLogin } from '../../helpers';
import { useAppSelector } from '../../storeHooks';
import classNames from 'classnames';
const WalletPicker = React.forwardRef(({ onBack, stickyHeader = false }, ref) => {
    const communicationLanguage = useAppSelector((state) => state.user.communicationLanguage);
    const currentWallet = useAppSelector((state) => state.user.wallet);
    const { data, error, isLoading } = useQuery('user-wallets', crmService.getUserWallets);
    const handleSwitchToWallet = useCallback((walletUsername) => {
        requestOAuthLogin(communicationLanguage, walletUsername);
    }, [communicationLanguage]);
    const renderContent = useMemo(() => {
        if (isLoading) {
            return (_jsx("div", Object.assign({ className: styles.loaderWrapper }, { children: _jsx(InlineLoader, {}) })));
        }
        if (error)
            return null;
        if (data) {
            return (_jsx("div", Object.assign({ className: styles.walletList }, { children: data.map((wallet) => (_jsxs("button", Object.assign({ className: styles.wallet, onClick: () => handleSwitchToWallet(wallet.username), disabled: (currentWallet === null || currentWallet === void 0 ? void 0 : currentWallet.id) === wallet.id }, { children: [_jsx("span", Object.assign({ className: classNames(styles.walletLabel, {
                                [styles.walletLabelActive]: (currentWallet === null || currentWallet === void 0 ? void 0 : currentWallet.id) === wallet.id,
                            }) }, { children: wallet.username })), _jsx(BalancePill, { balance: Number(wallet.balance) + Number(wallet.credit) })] }), wallet.id))) })));
        }
        return null;
    }, [currentWallet === null || currentWallet === void 0 ? void 0 : currentWallet.id, data, error, handleSwitchToWallet, isLoading]);
    return (_jsxs("div", Object.assign({ ref: ref, className: styles.container }, { children: [_jsxs("div", Object.assign({ className: classNames(styles.header, { [styles.headerSticky]: stickyHeader }) }, { children: [_jsx("button", Object.assign({ type: "button", onClick: onBack, className: styles.backButton }, { children: _jsx("img", { src: backIcon, alt: "back-icon" }) })), _jsx("h4", Object.assign({ className: styles.title }, { children: _t('account_info.choose_your_wallet') }))] })), renderContent] })));
});
export default React.memo(WalletPicker);
