const config = {
    soundstyle: 'classic',
    appName: 'Xbloom Neo',
    appTitle: 'Xbloom Capital - Neo App',
    feedApi: {
        url: 'wss://gateway.xbloomcapital.com',
    },
    baseURL: 'https://gateway.xbloomcapital.com',
    cashier: {
        useIframe: true,
        disableEducation: true,
        baseURL: 'https://my.xbloomcapital.com',
    },
    oAuth: {
        clientId: 'webtrader-xbloomcapital',
        clientSecret: 'rpjsSJvu1HIJvDCe',
    },
    affiliatePortalUrl: 'https://affiliates.xbloomcapital.com',
};
export default config;
