var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useMemo } from "react";
import styles from "./CoinsExchange.module.scss";
import { PageLayout } from "components/global/PageLayout";
import _t from "counterpart";
import { useAppSelector } from "../../../storeHooks";
import { crmService } from "wintrado-api";
import PopupAlertManager from "components/PopupAlert/PopupAlertManager";
import { formatCurrency } from "utils/currency";
import { useHistory } from "react-router-dom";
import Button from "components/Button/Button";
import coinsIcon from "../../../icons/coins.svg";
import { useExchangeRateQuery } from "../../../queries";
import LoyaltyTransitionStat from "components/LoyaltyTransitionStat/LoyaltyTransitionStat";
import Spacer from "components/Spacer/Spacer";
import { useMutation } from "react-query";
import { Desktop, Mobile } from "utils/responsiveHelpers";
const EXCHANGEABLE_COIN_VALUES = [300, 600, 1200, 2400, 4800];
const CoinsExchange = () => {
    var _a;
    const coinsBalance = useAppSelector((state) => state.user.coinsBalance);
    const accountCurrency = useAppSelector((state) => state.user.accountCurrency);
    const loyaltyLevel = useAppSelector((state) => state.user.loyaltyLevel);
    const history = useHistory();
    const exchangeRateQuery = useExchangeRateQuery();
    const exchangeCoinsMutation = useMutation((coinsAmount) => crmService.exchangeCoins(coinsAmount), {
        onSuccess: (data, coinsAmount) => {
            showSuccessAlert(coinsAmount, data.exchangeAmount, data.exchangeCurrency);
        },
        onError: () => {
            showErrorAlert();
        }
    });
    const showSuccessAlert = useCallback((coinsAmount, exchangeAmount, currency) => {
        PopupAlertManager.showAlert({
            type: "success",
            title: _t.translate("coins.success_title", {
                coins: coinsAmount,
                money: formatCurrency(exchangeAmount, currency)
            }),
            buttonLabel: _t.translate("coins.enjoy_trading"),
            buttonOnClick: () => history.push("/")
        });
    }, [history]);
    const showErrorAlert = useCallback(() => {
        PopupAlertManager.showAlert({
            type: "error",
            title: _t.translate("error.oops"),
            subtitle: _t.translate("error.generic"),
            buttonLabel: _t.translate("main.go_back")
        });
    }, []);
    const showConfirmationAlert = useCallback((coinsAmount) => {
        PopupAlertManager.showAlert({
            type: "success",
            title: _t.translate(loyaltyLevel.currentLevelRealMoney
                ? "coins.exchange_real_confirmation_title"
                : "coins.exchange_bonus_confirmation_title"),
            buttonLabel: _t.translate("coins.exchange"),
            buttonOnClick: () => exchangeCoinsMutation.mutate(coinsAmount),
            customIcon: require("../../../icons/coins-exchange.svg"),
            showCancelButton: true
        });
    }, [exchangeCoinsMutation, loyaltyLevel.currentLevelRealMoney]);
    const handleExchangeClicked = useCallback((coinsAmount) => __awaiter(void 0, void 0, void 0, function* () {
        showConfirmationAlert(coinsAmount);
    }), [showConfirmationAlert]);
    const renderCoinsExchangeContent = useMemo(() => {
        if (!exchangeRateQuery.isSuccess) {
            return null;
        }
        const { effectiveRate } = exchangeRateQuery.data;
        return (_jsxs(_Fragment, { children: [_jsx(Mobile, { children: _jsx(LoyaltyTransitionStat, {}) }), _jsxs("div", Object.assign({ className: styles.hero }, { children: [_jsx("img", { src: coinsIcon, alt: "coins", className: styles.coinsIcon }), _jsx("h5", Object.assign({ className: styles.label }, { children: _t.translate("coins.number_of_coins_you_have") })), _jsx("div", Object.assign({ className: styles.balanceWrapper }, { children: _jsx("span", { children: coinsBalance }) }))] })), _jsxs("div", Object.assign({ className: styles.exchangeContainer }, { children: [_jsx(Desktop, { children: _jsx(LoyaltyTransitionStat, {}) }), _jsx(Spacer, { y: true, large: true }), _jsx("h5", Object.assign({ className: styles.noticeTitle }, { children: _t.translate("coins.current_exchange_rates") })), _jsx("p", Object.assign({ className: styles.noticeSubtitle }, { children: _t.translate("coins.below_exchange_rates") })), _jsx("div", Object.assign({ className: styles.exchange }, { children: _jsxs("table", Object.assign({ className: styles.exchangeTable }, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: _t.translate("coins.number_of_coins") }), _jsx("th", { children: _t.translate("coins.money_value") }), _jsx("th", { children: _t.translate("coins.exchange") })] }) }), _jsx("tbody", { children: EXCHANGEABLE_COIN_VALUES.map((amount) => (_jsxs("tr", { children: [_jsx("td", { children: `${amount} ${_t.translate("main.coins")}` }), _jsx("td", { children: formatCurrency(amount * effectiveRate, accountCurrency) }), _jsx("td", { children: _jsx(Button, Object.assign({ green: true, onClick: () => handleExchangeClicked(amount), disabled: coinsBalance < amount }, { children: _t.translate("coins.exchange") })) })] }, amount))) })] })) }))] }))] }));
    }, [accountCurrency, coinsBalance, handleExchangeClicked, exchangeRateQuery.data, exchangeRateQuery.isSuccess]);
    return (_jsx(PageLayout, Object.assign({ title: _t.translate("coins.coins_exchange_title"), containerClassName: styles.layoutContainer, wrapperClassName: styles.layoutWrapper, loading: exchangeRateQuery.isLoading, error: (_a = exchangeRateQuery.error) === null || _a === void 0 ? void 0 : _a.message }, { children: _jsx("div", Object.assign({ className: styles.container }, { children: renderCoinsExchangeContent })) })));
};
export default React.memo(CoinsExchange);
