import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { playClickSound } from '../../helpers';
import { useAppDispatch, useAppSelector } from '../../storeHooks';
import { actions } from 'wintrado-api';
const LANGUAGES = [
    { name: 'English', code: 'en' },
    { name: 'Español', code: 'es' },
    { name: 'Francais', code: 'fr' },
    { name: 'Português ', code: 'pt' },
    { name: 'عربى', code: 'ar' },
    { name: 'Türkçe', code: 'tr' },
    { name: 'Pусский', code: 'ru' },
    { name: 'Italiano', code: 'it' },
    { name: 'Deutsch', code: 'de' },
    { name: 'हिन्दी', code: 'hi' },
    { name: 'اردو', code: 'ur' },
    { name: 'Kiswahili', code: 'sw' },
    { name: 'Malay', code: 'my' },
    { name: '日本語', code: 'ja' },
    { name: 'Tiếng Việt ', code: 'vi' },
    { name: 'ភាសាខ្មែរ', code: 'km' },
    { name: '中文', code: 'zh' },
];
const LanguageSelect = () => {
    const userLanguage = useAppSelector((state) => state.settings.language);
    const dispatch = useAppDispatch();
    const handleLanguageChanged = (event) => {
        var _a;
        event.preventDefault();
        const lang = (_a = event.currentTarget) === null || _a === void 0 ? void 0 : _a.getAttribute('data-language');
        playClickSound();
        dispatch(actions.userSaveLanguage(lang));
    };
    const currentLanguage = LANGUAGES.find((obj) => obj.code === userLanguage);
    if (!currentLanguage) {
        throw Error(`Language with code ${userLanguage} cannot be found.`);
    }
    return (_jsxs("div", Object.assign({ className: "dropupContainer" }, { children: [_jsxs("button", Object.assign({ onClick: playClickSound, type: "button", className: "btn dropdown-toggle", "data-toggle": "dropdown", "aria-haspopup": "true", "aria-expanded": "false" }, { children: [_jsx("img", { alt: `${userLanguage}-icon`, src: require(`../../images/${userLanguage}.png`) }), _jsxs("span", Object.assign({ className: "lang" }, { children: [" ", currentLanguage.name, " "] })), _jsx("span", Object.assign({ className: "arrow-down" }, { children: _jsx("img", { alt: "down-icon", src: require('../../icons/down-icon.svg') }) }))] })), _jsx("div", Object.assign({ className: "dropdown-menu wt-scroll" }, { children: LANGUAGES.map((item) => {
                    return (_jsx("div", { children: _jsxs("a", Object.assign({ className: "dropdown-item", href: "#", "data-language": item.code, onClick: handleLanguageChanged }, { children: [_jsx("img", { alt: `${item.code}-icon`, src: require(`../../icons/countries/${item.code}.svg`) }), _jsxs("span", { children: [" ", item.name, " "] })] })) }, item.code));
                }) }))] })));
};
export default LanguageSelect;
