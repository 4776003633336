import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import $ from "jquery";
import config from "config";
import { isIos } from "../../helpers";
import AnyLink from "components/global/AnyLink";
import _t from "counterpart";
import Button from "components/Button/Button";
import { useAppSelector } from "../../storeHooks";
const ModalDepositWithdrawal = () => {
    const user = useAppSelector((state) => state.user);
    const handleCloseModal = () => {
        $("#deposit_withdraw_modal").modal("hide");
    };
    const getDepositLink = () => {
        const { depositURL, useIframe } = config.cashier;
        return isIos() || !useIframe ? depositURL(user, _t.getLocale(), false) : "/deposit";
    };
    const getWithdrawLink = () => {
        const { withdrawURL, useIframe } = config.cashier;
        return isIos() || !useIframe ? withdrawURL(user, _t.getLocale(), false) : "/withdraw";
    };
    return (_jsxs("div", Object.assign({ id: "deposit_withdraw_modal", className: "modal fade bd-example-modal-sm", tabIndex: -1, role: "dialog", "aria-labelledby": "mySmallModalLabel", "aria-hidden": "true" }, { children: [_jsx("button", Object.assign({ type: "button", className: "close", "data-dismiss": "modal", "aria-label": "Close" }, { children: _jsx("span", Object.assign({ "aria-hidden": "true" }, { children: "\u00D7" })) })), _jsx("div", Object.assign({ className: "modal-dialog" }, { children: _jsxs("div", Object.assign({ className: "modal-content" }, { children: [_jsx("div", Object.assign({ className: "illustration" }, { children: _jsx("img", { src: require("../../images/modals/withdraw-deposit.svg"), alt: "withdraw-deposit-icon" }) })), _jsx("p", Object.assign({ className: "title" }, { children: _t.translate("modal.deposit_withdraw_label") })), _jsx("div", Object.assign({ className: "modal_btns" }, { children: _jsxs("div", Object.assign({ className: "holder" }, { children: [config.cashier && (_jsx(AnyLink, Object.assign({ to: getDepositLink(), onClick: handleCloseModal, "aria-label": _t.translate("page.deposit"), className: "left" }, { children: _jsx(Button, Object.assign({ green: true, inline: true }, { children: _t.translate("page.deposit") })) }))), config.cashier && (_jsx(AnyLink, Object.assign({ to: getWithdrawLink(), onClick: handleCloseModal, "aria-label": _t.translate("page.withdraw"), className: "right" }, { children: _jsx(Button, Object.assign({ red: true, inline: true }, { children: _t.translate("page.withdraw") })) })))] })) }))] })) }))] })));
};
export default React.memo(ModalDepositWithdrawal);
