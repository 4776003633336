import React from 'react';
import PositionComp from 'components/PositionComp';
import { actions, selectors } from 'wintrado-api';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Bubble from 'components/main/Bubble';

class OpenPositionsContainer extends React.PureComponent {
	scrollEndRef = React.createRef();

	scrollToEnd = () => {
		setTimeout(() => {
			if (this.scrollEndRef.current) {
				this.scrollEndRef.current.scrollIntoView({ behavior: 'smooth' });
			}
		}, 0);
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.openPositions.length < this.props.openPositions.length) {
			this.scrollToEnd();
		}
	}

	handlePositionSelected = (positionId) => {
		this.props.setActivePositionId(positionId);
	};

	render() {
		const {
			openPositions,
			showCloseAllProfitLoss,
			closingPositionsIds,
			closingAllPositions,
			accountCurrency,
			instruments,
		} = this.props;

		return (
			<div className="open-positions-container wt-x-scroll">
				{!showCloseAllProfitLoss &&
					!closingAllPositions &&
					openPositions.map((position) => {
						return (
							<PositionComp
								key={`position-${position.positionId}`}
								position={position}
								positionClosing={closingPositionsIds.includes(position.positionId)}
								onPositionSelected={this.handlePositionSelected}
								isActive={position.positionId === this.props.activePositionId}
								accountCurrency={accountCurrency}
								instrument={instruments[position.symbol]}
							/>
						);
					})}
				{showCloseAllProfitLoss && closingAllPositions && (
					<div
						style={{
							position: 'absolute',
							left: 0,
							right: 0,
							top: 0,
							bottom: 0,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<div style={{ position: 'relative' }}>
							<Bubble centered />
						</div>
					</div>
				)}
				<div ref={this.scrollEndRef} />
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		activePositionId: state.activePositionId,
		openPositions: selectors.openPositionsWithClosingAndNewSelector(state),
		showCloseAllProfitLoss: state.showCloseAllProfitLoss,
		closingPositionsIds: state.closingPositionsIds,
		closingAllPositions: state.closingAllPositions,
		accountCurrency: state.user.accountCurrency,
		instruments: state.instruments,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setActivePositionId: actions.setActivePositionId,
			positionsCloseAll: actions.positionsCloseAll,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenPositionsContainer);
