import React from 'react';
import { NavLink } from 'react-router-dom';
import _t from 'counterpart';
import chartIcon from '../../icons/chart.svg';
import dailyOverviewIcon from '../../icons/daily_overview.svg';
import settingsIcon from '../../icons/settings.svg';
import coinsIcon from '../../icons/coins_bw.svg';
import metatraderIcon from '../../icons/metatrader.svg';
import educationIcon from '../../icons/education.svg';
import copyTradingIcon from '../../icons/copy_trading.svg';
import config from 'config';
import AnyLink from 'components/global/AnyLink';
import { connect } from 'react-redux';
import { selectors } from 'wintrado-api';
import { wintradoHomeUrl } from '../../constants';

const extension = __BRAND__ === 'empirexinvest' ? 'png' : 'svg';
const logoT = require(`../../images/brands/${__BRAND__}/logo.${extension}`);

class SidebarLeft extends React.PureComponent {
	render() {
		const { activePackage, token, supportsCoins, supportsCopytrading, greyLabelLogo } = this.props;
		const logoUrl = greyLabelLogo?.data?.squareLogo ?? logoT;

		return (
			<section id="sidebarLeft" className="sidemenu">
				{!greyLabelLogo.isLoading && (
					<NavLink exact to="/" className="logo">
						<h1 style={{ backgroundImage: `url(${logoUrl})` }}>WebTrader</h1>
					</NavLink>
				)}

				<div className="powered-by">
					<span className="powered-by__label">{_t('main.powered_by')}</span>
					<AnyLink className="powered-by__link" to={wintradoHomeUrl}>
						Wintrado
					</AnyLink>
				</div>

				<ul>
					<li className="no-top">
						<NavLink data-toggle="tooltip" data-placement="right" title={_t.translate('page.trading')} exact to="/">
							<img src={chartIcon} alt="chart-icon" />
						</NavLink>
					</li>
					<li>
						<NavLink
							data-toggle="tooltip"
							data-placement="right"
							title={_t.translate('page.account_statement')}
							to="/account-statement"
						>
							<img src={dailyOverviewIcon} alt="account-statement-icon" />
						</NavLink>
					</li>
					{supportsCopytrading && (
						<li>
							<NavLink
								data-toggle="tooltip"
								data-placement="right"
								title={_t.translate('page.copy_trading')}
								to="/copy-trading/strategies"
							>
								<img src={copyTradingIcon} alt="copy-trading-icon" />
							</NavLink>
						</li>
					)}
					{supportsCoins && (
						<li>
							<NavLink data-toggle="tooltip" data-placement="right" title={_t.translate('page.coins')} to="/coins">
								<img src={coinsIcon} alt="coins-icon" />
							</NavLink>
						</li>
					)}
					{Boolean(config.cashier?.educationURL) && activePackage !== null && (
						<li>
							<AnyLink
								data-toggle="tooltip"
								data-placement="right"
								title={_t.translate('page.education')}
								to={config.cashier.educationURL(token, _t.getLocale())}
							>
								<img src={educationIcon} alt="education-icon" />
							</AnyLink>
						</li>
					)}
					{Boolean(config.cashier?.metatraderURL) && (
						<li>
							<NavLink
								onClick={this.closeSidebar}
								to="/metatrader"
								data-toggle="tooltip"
								data-placement="right"
								title="MetaTrader 4"
							>
								<img src={metatraderIcon} alt="metatrader-icon" />
							</NavLink>
						</li>
					)}
					<li className="extra m-t-2">
						<NavLink data-toggle="tooltip" data-placement="right" title={_t.translate('main.settings')} to="/settings">
							<img src={settingsIcon} alt="settings-icon" />
						</NavLink>
					</li>
				</ul>
			</section>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		greyLabelLogo: state.greyLabelLogo,
		activePackage: state.user.activePackage,
		token: state.user.token,
		supportsCoins: selectors.supportsCoinsSelector(state),
		supportsCopytrading: selectors.supportsCopytrading(state),
	};
};

export default connect(mapStateToProps, null)(SidebarLeft);
