import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions, selectors } from 'wintrado-api';
import ExchangeComp from '../ExchangeComp';
import { isMobileLayout } from '../../helpers';
import _t from 'counterpart';
import { MainCardHeader } from 'components/MainCardHeader/MainCardHeader';

const CATEGORY = {
	CURRENCIES: 'currencies',
	COMMODITIES: 'commodities',
	CRYPTO: 'crypto',
	INDICES: 'indices',
	EQUITIES: 'equities',
};

class MainInstrumentList extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			category: this.props.activeInstrument?.category ?? CATEGORY.COMMODITIES,
		};
	}

	onChangeInstrument(symbol, e) {
		e.preventDefault();
		const { setActiveInstrument, closeDrawer } = this.props;
		setActiveInstrument(symbol);
		if (isMobileLayout() && closeDrawer) {
			closeDrawer();
		}
	}

	onChangeCategory(category) {
		this.setState({ category });
	}

	renderInstruments(instruments) {
		return instruments.map((instrument) => {
			return (
				<ExchangeComp
					instrument={instrument}
					onClick={this.onChangeInstrument.bind(this, instrument.symbol)}
					key={`instruments-${instrument.symbol}`}
				/>
			);
		});
	}

	renderInstrumentsPerCategory = (category) => {
		if (category === CATEGORY.INDICES) return this.renderInstruments(this.props.indices);
		if (category === CATEGORY.CURRENCIES) return this.renderInstruments(this.props.currencies);
		if (category === CATEGORY.COMMODITIES) return this.renderInstruments(this.props.commodities);
		if (category === CATEGORY.CRYPTO) return this.renderInstruments(this.props.cryptos);
		if (category === CATEGORY.EQUITIES) return this.renderInstruments(this.props.equities);

		throw Error(`Unsupported instrument category ${category}`);
	};

	renderCategoryIcon(category) {
		return (
			<li className={this.state.category === category ? 'active' : null}>
				<a onClick={this.onChangeCategory.bind(this, category)}>
					<img src={require(`../../icons/category_${category}.svg`)} />
				</a>
			</li>
		);
	}

	render() {
		const { commodities, currencies, cryptos, indices, equities } = this.props;
		return (
			<div className="markets-box content-box" id="marketsSelector">
				<MainCardHeader
					title={_t.translate('main.markets')}
					endComponent={
						<ul className="markets-box__category" id="marketsCategorySelector">
							{currencies.length > 0 && this.renderCategoryIcon(CATEGORY.CURRENCIES)}
							{indices.length > 0 && this.renderCategoryIcon(CATEGORY.INDICES)}
							{commodities.length > 0 && this.renderCategoryIcon(CATEGORY.COMMODITIES)}
							{equities.length > 0 && this.renderCategoryIcon(CATEGORY.EQUITIES)}
						</ul>
					}
				/>
				<ul className="markets-box__list  wt-scroll markets-box__list--desktop">
					{this.renderInstrumentsPerCategory(this.state.category)}
				</ul>

				<ul className="markets-box__list wt-scroll markets-box__list--mobile">
					{currencies.length > 0 && (
						<>
							<h3 className="instruments-section-heading">{_t.translate('main.currency')}</h3>
							{this.renderInstruments(currencies)}
						</>
					)}

					{cryptos.length > 0 && (
						<>
							<h3 className="instruments-section-heading">{_t.translate('main.cryptocurrencies')}</h3>
							{this.renderInstruments(cryptos)}
						</>
					)}

					{indices.length > 0 && (
						<>
							<h3 className="instruments-section-heading">{_t.translate('main.indices')}</h3>
							{this.renderInstruments(indices)}
						</>
					)}

					{commodities.length > 0 && (
						<>
							<h3 className="instruments-section-heading">{_t.translate('main.materials')}</h3>
							{this.renderInstruments(commodities)}
						</>
					)}

					{equities.length > 0 && (
						<>
							<h3 className="instruments-section-heading">{_t.translate('main.equities')}</h3>
							{this.renderInstruments(equities)}
						</>
					)}
				</ul>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		currencies: selectors.currenciesSelector(state),
		indices: selectors.indicesSelector(state),
		commodities: selectors.commoditiesSelector(state),
		cryptos: selectors.cryptoSelector(state),
		equities: selectors.equitiesSelector(state),
		activeInstrument: state.activeInstrument,
		instrumentsTimezone: state.instrumentsTimezone,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setActiveInstrument: actions.setActiveInstrument,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(MainInstrumentList);
