import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _t from 'counterpart';
import positionsIcon from '../../icons/positions.svg';
import marketsIcon from '../../icons/markets.svg';
import leaderboardIcon from '../../icons/trophy.svg';
import config from 'config';
import Tooltip from 'react-tooltip-lite';
import referIcon from '../../icons/refer.svg';
import targetIcon from '../../icons/target.svg';
import depositWithdrawIcon from '../../icons/wallet.png';
import uploadDocumentsIcon from '../../icons/upload-documents.svg';
import logoutIcon from '../../icons/logout.svg';
import { NavLink } from 'react-router-dom';
import { loadLiveChat } from '../../helpers';
import classNames from 'classnames';
import MessagesIcon from 'components/MessagesIcon';
import { useAppSelector } from '../../storeHooks';
import { selectors } from 'wintrado-api';
import ModalRefer from 'components/modal/Refer';
const SidebarRight = ({ isTradingScreenVisible, setRightSideWidget, displayTwoRightWidgets }) => {
    const [referTooltipVisible, setReferTooltipVisible] = useState(false);
    const [copiedToClipboard, setCopiedToClipboard] = useState(false);
    const rightSideWidget = useAppSelector((state) => state.tradingScreen.rightSideWidget);
    const referralURL = useAppSelector((state) => state.user.referralURL);
    const referFriendsEnabled = useAppSelector((state) => state.settings.features.referFriendsEnabled);
    const token = useAppSelector((state) => state.user.token);
    const supportsCompetitions = useAppSelector(selectors.supportsCompetitionsSelector);
    const supportsMessages = useAppSelector(selectors.supportsMessagesSelector);
    const referralInputRef = useRef(null);
    const hideReferTooltip = useCallback((e) => {
        var _a;
        const mousedownInsideTooltip = Boolean((_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.closest('.react-tooltip-lite'));
        if (!mousedownInsideTooltip && referTooltipVisible) {
            setReferTooltipVisible(false);
        }
    }, [referTooltipVisible]);
    useEffect(() => {
        document.addEventListener('mousedown', hideReferTooltip);
        return () => {
            document.removeEventListener('mousedown', hideReferTooltip);
        };
    }, [hideReferTooltip]);
    const toggleReferTip = useCallback(() => {
        setReferTooltipVisible((prevState) => !prevState);
        setCopiedToClipboard(false);
    }, []);
    const handleReferralLinkFocused = useCallback(() => {
        copyReferralToClipboard();
    }, []);
    const copyReferralToClipboard = () => {
        var _a, _b;
        (_a = window.getSelection()) === null || _a === void 0 ? void 0 : _a.removeAllRanges();
        (_b = referralInputRef.current) === null || _b === void 0 ? void 0 : _b.select();
        document.execCommand('copy');
        setCopiedToClipboard(true);
        setTimeout(() => {
            var _a;
            setReferTooltipVisible(false);
            setCopiedToClipboard(false);
            (_a = window.getSelection()) === null || _a === void 0 ? void 0 : _a.removeAllRanges();
        }, 4500);
    };
    const isRightSideWidgetActive = useCallback((name) => {
        return isTradingScreenVisible && rightSideWidget === name;
    }, [isTradingScreenVisible, rightSideWidget]);
    const isMarketsAndPositionsActive = useMemo(() => {
        if (!isTradingScreenVisible)
            return false;
        return new RegExp('positions|markets').test(rightSideWidget) && displayTwoRightWidgets;
    }, [displayTwoRightWidgets, isTradingScreenVisible, rightSideWidget]);
    const pageIsDepositOrWithdraw = new RegExp('deposit|withdraw').test(location.pathname);
    return (_jsxs(_Fragment, { children: [_jsx("section", Object.assign({ id: "sidebarRight", className: "sidemenu" }, { children: _jsxs("ul", { children: [_jsx("li", { children: supportsMessages && (_jsx(NavLink, Object.assign({ to: "/messages", className: "messages" }, { children: _jsx(MessagesIcon, {}) }))) }), _jsx("li", Object.assign({ className: "positions" }, { children: _jsx("button", Object.assign({ type: "button", className: classNames({ active: isRightSideWidgetActive('positions') || isMarketsAndPositionsActive }), onClick: () => setRightSideWidget('positions'), "data-toggle": "tooltip", "data-placement": "left", title: _t.translate('main.open_positions') }, { children: _jsx("img", { src: positionsIcon, alt: "positions-icon" }) })) })), _jsx("li", Object.assign({ className: "instruments" }, { children: _jsx("button", Object.assign({ type: "button", className: classNames({ active: isRightSideWidgetActive('markets') || isMarketsAndPositionsActive }), onClick: () => setRightSideWidget('markets'), "data-toggle": "tooltip", "data-placement": "left", title: _t.translate('main.markets') }, { children: _jsx("img", { src: marketsIcon, alt: "markets-icon" }) })) })), config.cashier && (config.cashier.depositURL || config.cashier.withdrawURL) && (_jsx("li", Object.assign({ className: "deposit-withdraw", "data-toggle": "tooltip", "data-placement": "left", title: _t.translate('main.withdrawOrDeposit') }, { children: _jsx("button", Object.assign({ type: "button", "data-toggle": "modal", "data-target": "#deposit_withdraw_modal", className: classNames({ active: pageIsDepositOrWithdraw }) }, { children: _jsx("img", { src: depositWithdrawIcon, alt: "deposit-withdraw-icon", style: {
                                        width: 17,
                                        height: 17,
                                    } }) })) }))), config.cashier && config.cashier.uploadDocumentsURL && (_jsx("li", Object.assign({ "data-toggle": "tooltip", "data-placement": "left", title: _t.translate('main.upload-documents') }, { children: _jsx(NavLink, Object.assign({ to: "/upload-documents" }, { children: _jsx("img", { src: uploadDocumentsIcon, alt: "upload-documents-icon", style: {
                                        width: 17,
                                        height: 17,
                                    } }) })) }))), supportsCompetitions && (_jsx("li", Object.assign({ className: "leaderboard-competition" }, { children: _jsx("button", Object.assign({ type: "button", className: classNames({ active: isRightSideWidgetActive('leaderboard') }), onClick: () => setRightSideWidget('leaderboard'), "data-toggle": "tooltip", "data-placement": "left", title: _t.translate('leaderboard.heading') }, { children: _jsx("img", { src: leaderboardIcon, alt: "leaderboard" }) })) }))), referFriendsEnabled && referralURL && (_jsx("li", Object.assign({ className: "referral" }, { children: _jsx(Tooltip, Object.assign({ content: _jsxs("div", { children: [_jsx("div", Object.assign({ role: "button", tabIndex: 0, className: "copied-label", onClick: copyReferralToClipboard, onKeyDown: copyReferralToClipboard }, { children: copiedToClipboard ? _t.translate('main.copied_long') : _t.translate('main.copy_label') })), _jsx("input", { ref: referralInputRef, className: "referral-link-input", type: "text", value: _t.translate('main.invite_link', {
                                                referralURL,
                                            }), onFocus: handleReferralLinkFocused, readOnly: true })] }), isOpen: referTooltipVisible, tagName: "span", direction: "left", padding: "20px", background: "#24262b", forceDirection: true, className: "refer-tooltip" }, { children: _jsx("button", Object.assign({ type: "button", className: classNames('refer', {
                                        active: referTooltipVisible,
                                    }), onClick: toggleReferTip, "data-toggle": "tooltip", "data-placement": "left", title: _t.translate('main.invite_label') }, { children: _jsx("img", { src: referIcon, alt: "refer-icon" }) })) })) }))), config.affiliatePortalUrl && (_jsx("li", Object.assign({ className: "marketing" }, { children: _jsx("button", Object.assign({ type: "button", onClick: () => {
                                    window.open(`${config.affiliatePortalUrl}/login?token=${token}`, '_blank');
                                }, "data-toggle": "tooltip", "data-placement": "left", title: _t.translate('main.marketing') }, { children: _jsx("img", { src: targetIcon, alt: "target-icon", style: {
                                        width: 17,
                                        height: 17,
                                    } }) })) }))), Boolean(config.zendeskChatKey) && (_jsx("li", { children: _jsx("button", Object.assign({ type: "button", className: "button", onClick: loadLiveChat, "data-toggle": "tooltip", "data-placement": "left", title: _t.translate('main.support') }, { children: _jsx("i", Object.assign({ className: "material-icons" }, { children: "help_outline" })) })) })), _jsx("li", Object.assign({ className: "extra logout", "data-toggle": "tooltip", "data-placement": "left", title: _t.translate('main.logout') }, { children: _jsx("button", Object.assign({ type: "button", "data-toggle": "modal", "data-target": "#logout_modal" }, { children: _jsx("img", { src: logoutIcon, alt: "logout" }) })) }))] }) })), referFriendsEnabled && referralURL && _jsx(ModalRefer, { referralURL: referralURL })] }));
};
export default React.memo(SidebarRight);
