import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import _t from 'counterpart';
import config from 'config';
import Button from 'components/Button/Button';
import AnyLink from 'components/global/AnyLink';
import { playClickSound } from '../../helpers';
export const OpenAccountButton = ({ fullWidth = false }) => {
    const registrationUrl = config.cashier.registrationLink('', _t.getLocale());
    const handleRegisterClick = useCallback((e) => {
        playClickSound();
    }, []);
    return (_jsx(AnyLink, Object.assign({ to: registrationUrl, onClick: handleRegisterClick, className: "open-account-button" }, { children: _jsx(Button, Object.assign({ blue: true, fullWidth: fullWidth }, { children: _t.translate('login.create_account') })) })));
};
