import React from 'react';
import { PositionPlatform, PositionSide, PositionStatusCode } from 'wintrado-api';
import { formatCurrency } from 'utils/currency';
import { getDurationLabel, getSizeLabel } from '../helpers';
import { backgrounds, trading } from '../colors';
import InstrumentQuoteIndicator from 'components/InstrumentQuoteIndicator';
import PositionOpenPrice from 'components/PositionOpenPrice';
import clockIcon from '../icons/main/clock.svg';
import starIcon from '../icons/main/star.svg';
import timeIcon from '../icons/main/time.svg';
import warningIcon from '../icons/main/warning.svg';
import classNames from 'classnames';
import { Desktop, Mobile } from 'utils/responsiveHelpers';
import PositionProgressBar from 'components/global/PositionProgressBar';
import InstrumentIcon from 'components/InstrumentIcon/InstrumentIcon';

const DEFAULT_INSTRUMENT_DECIMALS = 3;

const _t = require('counterpart');

class PositionComp extends React.PureComponent {
	renderDesktopTimer() {
		const { position } = this.props;
		const profitLossTextStyle = {
			background: position.profitLoss >= 0 ? trading.positiveGrad : trading.negativeGrad,
			textAlign: 'center',
			overflow: 'hidden',
			width: '100%',
			height: 20,
		};

		return (
			<div className="position-progress-bar-desktop">
				<div className="position-progress-bar-desktop__wrapper">
					<PositionProgressBar positionId={position.positionId} />
				</div>
				<div className="position-progress-bar-desktop__profit-loss-wrapper">
					<span style={profitLossTextStyle}>{position.profitLoss.toFixed(2)}</span>
				</div>
			</div>
		);
	}

	renderMTPlaceholder = () => {
		return (
			<div className="mt4-placeholder">
				<span className="value">MT4</span>
			</div>
		);
	};

	onPositionSelected = () => {
		const { position, onPositionSelected, instrument } = this.props;
		if (onPositionSelected && instrument) {
			onPositionSelected(position.positionId);
		}
	};

	getProfitLoss = () => {
		const { position } = this.props;
		let profitLoss = 0;

		if (position.status === PositionStatusCode.OPEN) {
			profitLoss = position.profitLoss;
		} else if (position.status === PositionStatusCode.CLOSED) {
			profitLoss = position.closedPLInAccountCurrency;
			// if position was closed in one session
			if (typeof profitLoss !== 'number') {
				profitLoss = position.profitLoss;
			}
		}
		return profitLoss;
	};

	render() {
		const { instrument, position, accountCurrency, positionClosing, isActive } = this.props;
		if (!instrument && position.platform !== PositionPlatform.MT4) {
			return null;
		}

		const sideClass = position.side === PositionSide.BUY ? 'buy' : 'sell';
		const sizeLabel = getSizeLabel(position.size, instrument);
		const profitLoss = this.getProfitLoss();

		const plColor = () => {
			let bgColor;
			if (positionClosing) {
				bgColor = { background: backgrounds.lighterer };
			} else {
				bgColor = profitLoss >= 0 ? { background: trading.positiveGrad } : { background: trading.negativeGrad };
			}
			return bgColor;
		};

		const conditionalClasses = {
			active: isActive && position.status !== PositionStatusCode.NEW,
			closed: position.status === PositionStatusCode.CLOSED,
			closing: positionClosing,
			new: position.status === PositionStatusCode.NEW,
			mt4: position.platform === PositionPlatform.MT4,
		};

		const durationLabel = getDurationLabel(position.duration);

		return (
			<div>
				<Desktop>
					<li
						className={classNames('position-item-desktop', conditionalClasses)}
						onClick={this.onPositionSelected}
						key={`position-${position.positionId}`}
					>
						<div className="position-item-desktop__holder">
							<div className="position-item-desktop__main">
								<div className="position-item-desktop__general">
									<div className="info">
										<div className="info__left">
											<div className="info__icon">
                        <InstrumentIcon  symbol={instrument.symbol} imgUrl={instrument.iconUrl}/>
											</div>
											<div className="info__desc">
												<p className="name">
                          {instrument?.symbol ?? position.symbol}
												</p>
											</div>
										</div>
										<div className="info__right">
											<p className={`value ${sideClass}`}>
												{(position.openPrice || 0).toFixed(
													instrument ? instrument.decimals : DEFAULT_INSTRUMENT_DECIMALS
												)}
											</p>
										</div>
									</div>
								</div>
								<div className="position-item-desktop__details">
									<div className="details">
										<div className="details__item clock">
											<img src={timeIcon} />
											<span>{new Date(position.openedAt).toLocaleTimeString()}</span>
										</div>
										{Boolean(instrument) && Boolean(sizeLabel) && (
											<div className="details__item pts">
												<img src={starIcon} />
												<span>{sizeLabel}</span>
											</div>
										)}
										<div className="details__item time">
											<img src={clockIcon} />
											<span>{durationLabel}</span>
										</div>
										{Boolean(position.invest) && (
											<div className="details__item time">
												<img src={warningIcon} />
												<span>{formatCurrency(position.invest, accountCurrency)}</span>
											</div>
										)}
										{position.platform === PositionPlatform.MT4 && this.renderMTPlaceholder()}
									</div>
								</div>
							</div>
							{(position.status !== PositionStatusCode.CLOSED || positionClosing) &&
								position.platform !== PositionPlatform.MT4 && (
									<div className="position-item-desktop__timer">{this.renderDesktopTimer()}</div>
								)}
						</div>
						{position.platform === PositionPlatform.MT4 && this.renderMTPlaceholder()}
					</li>
				</Desktop>
				<Mobile>
					<li
						className={classNames('position-item-mobile ', conditionalClasses)}
						onClick={this.onPositionSelected}
						key={`position-${position.positionId}`}
					>
						<div className="position-item-mobile__holder">
							<div className="position-item-mobile__main">
								<div className="position-item-mobile__left">
									<div className="info">
										<div className="info__icon">
                      <InstrumentIcon  symbol={instrument.symbol} imgUrl={instrument.iconUrl}/>
										</div>
										<div className="info__desc">
											<p className="name">{instrument?.symbol ?? position.symbol}</p>
											<p className={`value ${sideClass}`}>
												{(position.openPrice || 0).toFixed(
													instrument ? instrument.decimals : DEFAULT_INSTRUMENT_DECIMALS
												)}
											</p>
										</div>
									</div>
								</div>
								<div className="position-item-mobile__right">
									<div className="details details--mobile">
										<PositionOpenPrice
											openPrice={(position.openPrice || 0).toFixed(
												instrument ? instrument.decimals : DEFAULT_INSTRUMENT_DECIMALS
											)}
											side={position.side}
										/>
										{Boolean(instrument) && (
											<InstrumentQuoteIndicator
												symbol={position.symbol}
												showRiseFall
												type={position.side === PositionSide.BUY ? 'bid' : 'ask'}
											/>
										)}
									</div>
								</div>
							</div>
							{Boolean(instrument) && (
								<div className="position-item-mobile__bottom" style={plColor()}>
									<span>{formatCurrency(profitLoss, accountCurrency)}</span>
								</div>
							)}
						</div>
						{(position.status !== PositionStatusCode.CLOSED || positionClosing) &&
							position.platform !== PositionPlatform.MT4 && (
								<div className="position-item-mobile__timer">
									<PositionProgressBar positionId={position.positionId} />
								</div>
							)}
						{position.platform === PositionPlatform.MT4 && <div>{this.renderMTPlaceholder()}</div>}
					</li>
				</Mobile>
			</div>
		);
	}
}

export default PositionComp;
