import React from 'react';
import { formatCurrency } from 'utils/currency';
import classNames from 'classnames';

const goldCup = require('../../icons/gold-cup.svg');
const bronzeCup = require('../../icons/bronze-cup.svg');
const silverCup = require('../../icons/silver-cup.svg');

export class LeaderboardHeader extends React.PureComponent {
	render() {
		const { firstPrizeInUSD, secondPrizeInUSD, thirdPrizeInUSD } = this.props.competition;
		return (
			<div className="cups-container">
				<div
					className={classNames('small-cup-container', { 'small-cup-container--hidden': secondPrizeInUSD === null })}
				>
					<>
						<img src={silverCup} alt="silver-cup" />
						<p>{`$${secondPrizeInUSD}`}</p>
					</>
				</div>
				<div className="big-cup-container">
					<img src={goldCup} alt="gold-cup" />
					<p>{`$${firstPrizeInUSD}`}</p>
				</div>
				<div className={classNames('small-cup-container', { 'small-cup-container--hidden': thirdPrizeInUSD === null })}>
					<>
						<img src={bronzeCup} alt="bronze-cup" />
						<p>{`$${thirdPrizeInUSD}`}</p>
					</>
				</div>
			</div>
		);
	}
}
