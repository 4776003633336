import React from 'react';
import _t from 'counterpart';

export default class ModalLogout extends React.Component {
	render() {
		return (
			<div
				id="force_logout_modal"
				className="modal fade bd-example-modal-sm"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="mySmallModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						{_t.translate('modal.force_logout_notice')}
						<div className="modal_btns">
							<a href="#" data-dismiss="modal" aria-label="Close" className="btn right">
								{_t.translate('modal.relogin')}
							</a>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
