import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import classNames from "classnames";
import { actions } from "wintrado-api";
import { useAppDispatch, useAppSelector } from "../../storeHooks";
const MainMenuSettings = () => {
    const sound = useAppSelector(state => state.settings.sound);
    const bellSound = useAppSelector(state => state.settings.bellSound);
    const dispatch = useAppDispatch();
    const onSoundChange = (e) => {
        e.preventDefault();
        dispatch(actions.userSaveSound(!sound));
    };
    const onBellSoundChange = (e) => {
        e.preventDefault();
        dispatch(actions.userSaveBellSound(!bellSound));
    };
    return (_jsxs("ul", Object.assign({ className: "trading-menu settings" }, { children: [_jsx("li", Object.assign({ className: classNames({ active: sound }) }, { children: _jsx("a", Object.assign({ href: "#", onClick: onSoundChange }, { children: _jsx("span", { children: _jsx("img", { src: require(`../../icons/${sound ? "" : "mute_"}speaker.svg`), alt: "sound" }) }) })) })), _jsx("li", Object.assign({ className: classNames({ active: bellSound }) }, { children: _jsx("a", Object.assign({ href: "#", onClick: onBellSoundChange }, { children: _jsx("span", { children: _jsx("img", { src: require(`../../icons/${bellSound ? "" : "mute_"}bell.svg`), alt: "bell" }) }) })) }))] })));
};
export default React.memo(MainMenuSettings);
