import React from 'react';
import config from 'config';
import $ from 'jquery';
import { playClickSound } from '../../helpers';

const _t = require('counterpart');

export default class ModalRefer extends React.PureComponent {
	state = {
		copiedText: false,
	};

	handleCloseClicked = () => {
		playClickSound();
		setTimeout(() => {
			this.setState({
				copiedText: false,
			});
		}, 100);
	};

	handleInviteFriends = (e) => {
		e.preventDefault();
		const { referralURL } = this.props;
		const { appName } = config;

		if (navigator.share) {
			this.dismissModal();
			navigator
				.share({
					title: appName,
					url: _t.translate('main.invite_link', {
						referralURL,
					}),
				})
				.catch(console.error);
		} else {
			this.copyReferralURLToClipboard();
		}
	};

	copyReferralURLToClipboard = () => {
		const referralURLText = document.getElementById('referralURL');
		referralURLText.select();
		referralURLText.setSelectionRange(0, 99999);
		document.execCommand('copy');

		this.showCopiedTextLabel();
	};

	showCopiedTextLabel = () => {
		this.setState({
			copiedText: true,
		});
	};

	dismissModal = () => {
		$('#refer_modal').modal('hide');
	};

	render() {
		const { referralURL } = this.props;
		const { copiedText } = this.state;

		return (
			<div
				id="refer_modal"
				className="modal fade bd-example-modal-sm"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="mySmallModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						{copiedText
							? _t.translate('main.copied_long')
							: _t.translate('main.mobile_invite_label', { appName: config.appName })}
						<input
							id="referralURL"
							type="text"
							readOnly
							value={_t.translate('main.invite_link', {
								referralURL,
							})}
						/>
						<div className="modal_btns">
							<a
								href="#"
								onClick={this.handleCloseClicked}
								data-dismiss="modal"
								aria-label="Close"
								className="btn left"
							>
								{_t.translate('modal.close')}
							</a>
							{!copiedText && (
								<a href="#" onClick={this.handleInviteFriends} className="btn right">
									{_t.translate('main.invite_label')}
								</a>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
