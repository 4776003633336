import React from 'react';
import { playClickSound } from '../../helpers';
import _t from 'counterpart';

export default class SoundstyleSelect extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			soundstyle: [
				{ name: _t.translate('settings.classic'), code: 'classic' },
				{ name: _t.translate('settings.africa'), code: 'africa' },
			],
		};
	}

	render() {
		const { soundstyle, handleChangeSoundstyle } = this.props;
		const currentSoundstyle = this.state.soundstyle.find((obj) => obj.code === soundstyle);
		return (
			<div className="dropupContainer">
				<button
					onClick={playClickSound}
					type="button"
					className="btn dropdown-toggle"
					data-toggle="dropdown"
					aria-haspopup="true"
					aria-expanded="false"
				>
					<span> {currentSoundstyle.name} </span>
				</button>
				<div className="dropdown-menu wt-scroll soundstyle">
					{this.state.soundstyle.map((item) => {
						return (
							<div key={item.code}>
								<a className="dropdown-item" href="#" data-soundstyle={item.code} onClick={handleChangeSoundstyle}>
									<span> {item.name} </span>
								</a>
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}
