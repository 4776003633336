import React from "react";
import { playClickSound } from "../../../helpers";
import config from "config";
import { OpenAccountButton } from "components/main/OpenAccountButton";
import BalanceStat from "components/main/BalanceStat";
import CoinsStat from "components/main/CoinsStat";
import RankStat from "components/main/RankStat";
import AccountInfo from "components/AccountInfo";
import CustomBanner from "components/CustomBanner";
import LoyaltyStat from "components/LoyaltyStat/LoyaltyStat";

class DesktopHeader extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showBalance: true
    };

    this.toggleBalance = this.toggleBalance.bind(this);
  }

  toggleBalance(e) {
    e.preventDefault();
    playClickSound();

    this.setState({
      showBalance: !this.state.showBalance
    });
  }

  renderStats() {
    const { totalPL, balance, accountCurrency, equity, freeMargin, marginCall, coinsBalance, supportsCoins } =
      this.props;
    return (
      <div className="stat__holder flex-1">
        <>
          <BalanceStat
            totalPL={totalPL}
            balance={balance}
            accountCurrency={accountCurrency}
            equity={equity}
            freeMargin={freeMargin}
            marginCall={marginCall}
          />
        </>
        {supportsCoins && <CoinsStat amount={coinsBalance} />}
      </div>
    );
  }

  navigateToLeaderboard = () => {
    this.props.setRightSideWidget("leaderboard");
  };

  render() {
    const { leaderboardUser } = this.props;
    return (
      <div className="header">
        <div className="flex-1">
          <LoyaltyStat />
        </div>

        {this.renderStats()}

        <div className="profile-leaderboard-holder">

          {Boolean(this.props.username) && <AccountInfo username={this.props.username} />}
          {Boolean(leaderboardUser) && (
            <RankStat
              change={leaderboardUser.change}
              rank={leaderboardUser.rank}
              nickname={leaderboardUser.nickname}
              small
              navigateToLeaderboard={this.navigateToLeaderboard}
            />
          )}
        </div>
      </div>
    );
  }
}

export default DesktopHeader;
