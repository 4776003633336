import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Modal from 'react-modal';
import _t from 'counterpart';
import { useAppDispatch, useAppSelector } from '../../storeHooks';
import ModalCloseButton from '../ModalCloseButton';
import ModalFooterButtons from '../ModalFooterButtons';
import { actions } from 'wintrado-api';
import buyCircleIcon from '../../icons/main/buy-circle.svg';
import sellCircleIcon from '../../icons/main/sell-circle.svg';
import Button from 'components/Button/Button';
const ModalInstrumentTrendExplainer = () => {
    const { shown } = useAppSelector((state) => state.modals.instrumentTrendExplainer);
    const dispatch = useAppDispatch();
    const handleCloseModal = () => {
        dispatch(actions.hideInstrumentTrendExplainerModal());
    };
    return (_jsxs(Modal, Object.assign({ isOpen: shown, onRequestClose: handleCloseModal, className: "ReactModal__Content ReactModal__Content--light", overlayClassName: "ReactModal__Overlay" }, { children: [_jsx(ModalCloseButton, { handleClose: handleCloseModal, dark: true }), _jsxs("div", Object.assign({ className: "instrument-trend-explainer" }, { children: [_jsx("h2", Object.assign({ className: "title" }, { children: _t.translate('modal.instrument_trend_explainer.title') })), _jsx("img", { src: sellCircleIcon, className: "icon", alt: "sell-icon" }), _jsx("h5", { children: _t.translate('modal.instrument_trend_explainer.sell_positions') }), _jsx("p", Object.assign({ className: "description" }, { children: _t.translate('modal.instrument_trend_explainer.sell_description') })), _jsx("img", { src: buyCircleIcon, className: "icon", alt: "buy-icon" }), _jsx("h5", { children: _t.translate('modal.instrument_trend_explainer.buy_positions') }), _jsx("p", Object.assign({ className: "description" }, { children: _t.translate('modal.instrument_trend_explainer.buy_description') })), _jsx(ModalFooterButtons, { children: _jsx(Button, Object.assign({ red: true, onClick: handleCloseModal }, { children: _t.translate('modal.close') })) })] }))] })));
};
export default ModalInstrumentTrendExplainer;
